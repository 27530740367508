import { default as i18n } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string) =>
        import(`../../Translations/${language}/translation.json`),
    ),
  )
  .use(initReactI18next)
  .init({
    fallbackLng: "us",
    load: "languageOnly",
    debug: true,
    returnObjects: true,
  });

export default i18n;
