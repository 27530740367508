import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { ROUTES } from "Constants";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function InfoMenu() {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const anchorInfoMenuRef = useRef(null);
  const showBugCollectorFunction = useRef<(() => void) | undefined>(undefined);
  const showFeedbackCollectorFunction = useRef<(() => void) | undefined>(
    undefined,
  );

  const [infoMenuOpen, setInfoMenuOpen] = useState(false);

  const handleInfoMenuToggle = useCallback(() => {
    setInfoMenuOpen((prevOpen) => !prevOpen);
  }, []);

  const handleItemClick = useCallback(
    (route: string) => {
      navigate(route);
      handleInfoMenuToggle();
    },
    [handleInfoMenuToggle, navigate],
  );

  useEffect(() => {
    // Get collector ids from environment variables
    const feedbackCollectorId: string =
      process.env.REACT_APP_FEEDBACK_COLLECTOR_ID || "";
    const bugReportCollectorId: string =
      process.env.REACT_APP_BUG_REPORT_COLLECTOR_ID || "";
    // If vars were not set in the environment, ids above falls back to ""
    // and none of feedback windows will work

    window["ATL_JQ_PAGE_PROPS"] = {
      [feedbackCollectorId]: {
        triggerFunction: function (showCollectorDialog: any) {
          showFeedbackCollectorFunction.current = showCollectorDialog;
        },
      },
      [bugReportCollectorId]: {
        triggerFunction: function (showCollectorDialog: any) {
          showBugCollectorFunction.current = showCollectorDialog;
        },
      },
    };

    const scriptBugReport = document.createElement("script");
    scriptBugReport.src = process.env.REACT_APP_BUG_REPORT_URL
      ? process.env.REACT_APP_BUG_REPORT_URL
      : "";
    document.body.appendChild(scriptBugReport);
    const scriptFeedbackReport = document.createElement("script");

    scriptFeedbackReport.src = process.env.REACT_APP_FEEDBACK_URL
      ? process.env.REACT_APP_FEEDBACK_URL
      : "";
    document.body.appendChild(scriptFeedbackReport);

    return () => {
      document.body.removeChild(scriptBugReport);
      document.body.removeChild(scriptFeedbackReport);
    };
  }, []);

  return (
    <>
      <Tooltip
        title={t("general.navigation.infoMenu")}
        disableInteractive
        arrow
      >
        <span>
          <IconButton
            ref={anchorInfoMenuRef}
            aria-controls={infoMenuOpen ? "info-menu-list-grow" : undefined}
            aria-haspopup="true"
            aria-label="Info menu"
            color="inherit"
            onClick={handleInfoMenuToggle}
          >
            <InfoOutlined style={{ fontSize: 38 }} />
          </IconButton>
        </span>
      </Tooltip>
      <Popper
        open={infoMenuOpen}
        anchorEl={anchorInfoMenuRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleInfoMenuToggle}>
                <MenuList autoFocusItem={infoMenuOpen} id="info-menu-list-grow">
                  <MenuItem
                    onClick={() => {
                      handleItemClick(ROUTES.PROFILE.RELEASENOTES);
                    }}
                  >
                    {t("general.navigation.releaseNotes")}
                  </MenuItem>
                  <hr style={{ borderTop: "1px solid #00000029" }} />
                  <MenuItem
                    onClick={() => {
                      handleItemClick(ROUTES.MISC.HELP);
                    }}
                  >
                    {t("general.navigation.help")}
                  </MenuItem>
                  <hr style={{ borderTop: "1px solid #00000029" }} />
                  <MenuItem
                    onClick={() => {
                      !!showBugCollectorFunction.current &&
                        showBugCollectorFunction.current();
                      handleInfoMenuToggle();
                    }}
                  >
                    {t("general.navigation.reportBug")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      !!showFeedbackCollectorFunction.current &&
                        showFeedbackCollectorFunction.current();
                      handleInfoMenuToggle();
                    }}
                  >
                    {t("general.navigation.reportFeedback")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
