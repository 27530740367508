import { Add, Delete } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { Ref, useCallback } from "react";
import { useTranslation } from "react-i18next";

export type BaseDataTableToolbarProps = {
  showDelete: boolean;
  onAddClick?: () => void;
  addButton?: JSX.Element;
  onDeleteClick?: () => void;
  setColumnsButtonEl: Ref<HTMLButtonElement> | undefined;
};

export const BaseDataTableToolbar: React.FC<BaseDataTableToolbarProps> = ({
  showDelete,
  onAddClick,
  addButton: customAddButton,
  onDeleteClick,
  setColumnsButtonEl,
}) => {
  const [t] = useTranslation();

  const AddButton = useCallback(() => {
    if (onAddClick) {
      return (
        <Button
          color="primary"
          variant="contained"
          sx={{ ml: 1 }}
          startIcon={<Add />}
          onClick={onAddClick}
        >
          {t("general.buttons.add")}
        </Button>
      );
    } else {
      return customAddButton ?? null;
    }
  }, [onAddClick, t, customAddButton]);

  return (
    <GridToolbarContainer sx={{ justifyContent: "space-between", p: 3 }}>
      {!showDelete && (
        <>
          <Box id="table-toolbar-start-slot">
            <GridToolbarQuickFilter />
          </Box>
          <Box id="table-toolbar-end-slot">
            <GridToolbarColumnsButton ref={setColumnsButtonEl} />
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            <AddButton />
          </Box>
        </>
      )}
      {onDeleteClick && showDelete && (
        <Button
          color="primary"
          variant="contained"
          startIcon={<Delete />}
          onClick={onDeleteClick}
        >
          {t("general.buttons.delete")}
        </Button>
      )}
    </GridToolbarContainer>
  );
};
