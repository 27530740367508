import PolylineIcon from "@mui/icons-material/Polyline";
import RoomIcon from "@mui/icons-material/Room";
import { Box, ToggleButtonGroup, Tooltip } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DRAWINGMODE } from "./utils";

interface IDrawingModeToggle {
  drawingMode: DRAWINGMODE;
  setDrawingMode: React.Dispatch<React.SetStateAction<DRAWINGMODE>>;
}

export const DrawingModeToggle: React.FC<IDrawingModeToggle> = ({
  drawingMode,
  setDrawingMode,
}) => {
  const [t] = useTranslation();
  return (
    <Box
      sx={{
        position: "absolute",
        top: 15,
        left: 15,
        opacity: 1,
      }}
    >
      <ToggleButtonGroup
        value={drawingMode}
        sx={{
          backgroundColor: "#fff",
        }}
        size="large"
        color="primary"
        exclusive
        onChange={(e, value) => {
          value !== null && setDrawingMode(value);
        }}
        aria-label="drawing-mode"
      >
        <Tooltip title={t("parcels.notification.markerDrawing.tooltip")}>
          <ToggleButton value={DRAWINGMODE.MARKER} aria-label="marker">
            <RoomIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title={t("parcels.notification.polygonDrawing.tooltip")}>
          <ToggleButton value={DRAWINGMODE.POLYGON} aria-label="polygon">
            <PolylineIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Box>
  );
};
