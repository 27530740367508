import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();
  return [
    {
      field: "internalId",
      headerName: t("customers.fields.id"),
    },
    {
      field: "companyName",
      headerName: t("customers.fields.name"),
    },
    {
      field: "address",
      headerName: t("customers.fields.address"),
      valueGetter: (params) =>
        [
          ...(params.row.street ? [params.row.street] : []),
          [params.row.zipCode, params.row.city].join(" "),
        ].join(", "),
    },
    {
      field: "email",
      headerName: t("general.fields.email"),
    },
    {
      field: "phone",
      headerName: t("general.fields.phone"),
    },
    {
      field: "mobile",
      headerName: t("customers.fields.mobile"),
    },
    {
      field: "employees",
      headerName: t("customers.fields.persons"),
    },
    {
      field: "notes",
      headerName: t("general.fields.notes"),
    },
    {
      field: "category",
      headerName: t("customers.fields.category"),
      valueGetter: (params) =>
        params.value._id !== -1 ? params.value.name : "",
    },
    {
      field: "creationDate",
      headerName: t("customers.fields.creationDate"),
      valueGetter: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "mainContact",
      headerName: t("customers.fields.mainContact"),
      valueGetter: (params) =>
        params.value._id !== -1
          ? `${params.value.firstName} ${params.value.lastName}`
          : "",
    },
  ];
};

export const defaultHiddenColumns = {
  internalId: false,
  id: false,
  creationDate: false,
  employees: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "number", access: ["internalId"], default: "-" },
  { type: "string", access: ["companyName"] },
  { type: "string", access: ["street"] },
  { type: "string", access: ["zipCode"] },
  { type: "string", access: ["city"] },
  { type: "string", access: ["email"] },
  { type: "string", access: ["phone"] },
  { type: "string", access: ["mobile"] },
  { type: "string", access: ["notes"] },
];
