import { useTranslation } from "react-i18next";

export enum ORGANIZATION_TYPE {
  CUSTOMER_CATEGORIES = "category",
  MACHINE_MANUFACTURERS = "manufacturer",
  SERVICE_TYPES = "serviceKind",
  GRAPE_VARIETIES = "crop",
  CULTIVATION_METHODS = "trainingType",
  SOIL_TYPES = "soil",
}

export const useCustomerCatgeories = () => {
  const [t] = useTranslation();
  return [
    {
      title: t("customers.fields.category"),
      id: "ct125",
      route: ORGANIZATION_TYPE.CUSTOMER_CATEGORIES,
    },
  ];
};

export const useMachineManufacturers = () => {
  const [t] = useTranslation();
  return [
    {
      title: t("machines.fields.manufacturer"),
      id: "ert139",
      route: ORGANIZATION_TYPE.MACHINE_MANUFACTURERS,
    },
  ];
};

export const useServiceTypes = () => {
  const [t] = useTranslation();
  return [
    {
      title: t("services.fields.serviceKind"),
      id: "serviceServiceKind123",
      route: ORGANIZATION_TYPE.SERVICE_TYPES,
    },
  ];
};

export const useParcels = () => {
  const [t] = useTranslation();
  return [
    {
      title: t("parcels.fields.crop"),
      id: "ag125",
      route: ORGANIZATION_TYPE.GRAPE_VARIETIES,
    },
    {
      title: t("parcels.fields.formOfEducation"),
      id: "ert127",
      route: ORGANIZATION_TYPE.CULTIVATION_METHODS,
    },
    {
      title: t("parcels.fields.soil"),
      id: "gfg124",
      route: ORGANIZATION_TYPE.SOIL_TYPES,
    },
  ];
};
