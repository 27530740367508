import { GridRowId } from "@mui/x-data-grid";
import { getMachine } from "Api";
import { MachineInformationModal } from "ProjectComponents";
import { BaseDataTable } from "ProjectComponents/tableV2/baseDataTable/BaseDataTable";
import { type AppState } from "Store";
import {
  deleteMachines,
  getMachines,
  getMachinesInitial,
  resetSearch,
  setTableHiddenColumns,
} from "Store/machines";
import { LocalMachineType, machinesSpec } from "Store/machines/specs";
import { searchSpecShorthand } from "Utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { quickSearchColumns, useColumns } from "./tableConfig";

export const Machines: React.FC = () => {
  const dispatch = useDispatch();

  const {
    initialMeta,
    listMeta: machinesMeta,
    list: machinesList,
    maxCount: machinesMaxCount,
    listUpdateLoading: tableLoading,
    hiddenColumns,
    searchData,
    filters,
    loading,
  } = useSelector((state: AppState) => state.machines);

  const [machineModalIsOpen, setMachineModalIsOpen] = useState(false);
  const [machineToUpdate, setMachineToUpdate] = useState<
    LocalMachineType | undefined
  >();

  const { state } = useLocation();

  useEffect(() => {
    setMachineModalIsOpen(machineToUpdate !== undefined);
  }, [machineToUpdate]);

  useEffect(() => {
    dispatch(getMachinesInitial(machinesMeta));

    return () => {
      dispatch(resetSearch());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinesMeta]);

  useEffect(() => {
    if (state?.id) {
      (async () => {
        const machine = await getMachine({ id: state.id, spec: machinesSpec });
        setMachineToUpdate(machine.data);
      })();
    }
  }, [state]);

  const columns = useColumns();

  const handleTableRowsDelete = useCallback((ids: GridRowId[]) => {
    dispatch(
      deleteMachines({
        ids: ids as number[],
      }),
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTablePagination = useCallback(
    (currentPage: number, numberOfRows: number) => {
      dispatch(
        getMachines({
          params: {
            ...machinesMeta,
            page: currentPage,
            pageLength: numberOfRows,
          },
          search: searchData,
          filters,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [machinesMeta, searchData, filters],
  );

  const handleTableSearch = useCallback(
    (query: string) => {
      const config = {
        params: machinesMeta,
        search: searchSpecShorthand(query, quickSearchColumns),
      };

      dispatch(getMachines(config));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [machinesMeta],
  );

  const handleRowClick = useCallback(
    (id: GridRowId) => {
      const machine = machinesList.find((machine) => machine._id === id);
      setMachineToUpdate(machine);
    },
    [machinesList],
  );

  const handleCreateMachineClick = useCallback((): void => {
    setMachineModalIsOpen(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeColumnsView = useCallback((values: Record<string, boolean>) => {
    dispatch(setTableHiddenColumns(values));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = useCallback(() => {
    setMachineToUpdate(undefined);
    setMachineModalIsOpen(false);
  }, []);

  return (
    <>
      <BaseDataTable
        columnVisibilityModel={hiddenColumns}
        columns={columns}
        handlePagination={handleTablePagination}
        handleQuickSearch={handleTableSearch}
        loading={loading || tableLoading || initialMeta.loading}
        maxCount={machinesMaxCount}
        onColumnVisibilityModelChange={onChangeColumnsView}
        onRowClick={handleRowClick}
        rows={machinesList}
        toolbarProps={{
          onAddClick: handleCreateMachineClick,
          onDeleteClick: handleTableRowsDelete,
        }}
        paginationModel={{
          page: machinesMeta.page,
          pageSize: machinesMeta.pageLength,
        }}
      />
      <MachineInformationModal
        isOpen={machineModalIsOpen}
        onClose={handleModalClose}
        machine={machineToUpdate}
      />
    </>
  );
};
