import { GridColumnVisibilityModel, GridRowId } from "@mui/x-data-grid";
import { getParcel } from "Api";
import { ConfirmDialog } from "Components";
import { ROUTES } from "Constants";
import { ParcelInformationModal } from "ProjectComponents";
import { BaseDataTable } from "ProjectComponents/tableV2/baseDataTable/BaseDataTable";
import { type AppState } from "Store";
import { getCustomersInitial } from "Store/customers";
import {
  deleteParcels,
  getParcels,
  getParcelsInitial,
  resetSearch,
  setTableHiddenColumns,
  TABLE_TYPES,
} from "Store/parcels";
import { LocalParcelType } from "Store/parcels/specs";
import { searchSpecShorthand } from "Utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AddParcelButton } from "./components/AddParcelButton";
import { ImportParcelsModal } from "./components/importParcelsModal/importParcelsModal";
import { SendImportLinkModal } from "./components/sendImportLinkModal/sendImportLinkModal";
import { quickSearchColumns, useColumns } from "./tableConfig";

export const Parcels: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [parcelInformationModalIsOpen, setParcelInformationModalIsOpen] =
    useState(false);
  const [parcelToUpdate, setParcelToUpdate] = useState<
    LocalParcelType | undefined
  >();
  const [isImportParcelsModalOpen, setIsImportParcelsModalOpen] =
    useState(false);
  const [isSendImportLinkModalOpen, setIsSendImportLinkModalOpen] =
    useState(false);
  const [isCreateCustomersModalOpen, setIsCreateCustomersModalOpen] =
    useState(false);
  const [createCustomersModalText, setCreateCustomersModalText] = useState("");

  const {
    listMeta,
    list: parcelsList,
    maxCount: listMaxCount,
    listUpdateLoading: tableLoading,
    hiddenColumns,
    searchData,
    filters,
    loading,
  } = useSelector((state: AppState) => state.parcels);

  const { maxCount: customersCount } = useSelector(
    (state: AppState) => state.customers,
  );

  const unitOfMeasurement = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const { state } = useLocation();

  useEffect(() => {
    dispatch(getParcelsInitial(listMeta));

    if (customersCount === 0) {
      dispatch(getCustomersInitial(listMeta));
    }

    return () => {
      dispatch(resetSearch());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersCount, listMeta]);

  useEffect(() => {
    if (state?.id) {
      (async () => {
        const parcel = await getParcel({ id: state.id });
        setParcelToUpdate(parcel.data);
        setParcelInformationModalIsOpen(true);
      })();
    }
  }, [state]);

  const columns = useColumns(unitOfMeasurement);

  const handleTableRowsDelete = useCallback((deletedIds: GridRowId[]) => {
    dispatch(deleteParcels(deletedIds as number[]));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTablePagination = useCallback(
    (currentPage: number, numberOfRows: number) => {
      dispatch(
        getParcels({
          params: {
            ...listMeta,
            page: currentPage,
            pageLength: numberOfRows,
          },
          search: searchData,
          filters,
        }),
      );
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta, searchData, filters],
  );

  const handleTableSearch = useCallback(
    (query: string) => {
      const config = {
        params: listMeta,
        search: searchSpecShorthand(query, quickSearchColumns),
      };

      dispatch(getParcels(config));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta],
  );

  const onChangeColumnsView = useCallback(
    (values: GridColumnVisibilityModel) => {
      dispatch(setTableHiddenColumns(TABLE_TYPES.PARCELS_TABLE, values));
    },
    [dispatch],
  );

  const onCreateClick = useCallback(() => {
    setParcelInformationModalIsOpen(true);
  }, []);

  const onImportClick = useCallback(() => {
    if (customersCount === 0) {
      setCreateCustomersModalText(t("parcels.noCustomersAvailableModal.text"));
      setIsCreateCustomersModalOpen(true);
    } else {
      setIsImportParcelsModalOpen(true);
    }
  }, [customersCount, t]);

  const onSendImportLinkClick = useCallback(() => {
    if (customersCount === 0) {
      setCreateCustomersModalText(
        t("parcels.noCustomersAvailableModal.textSendLink"),
      );
      setIsCreateCustomersModalOpen(true);
    } else {
      setIsSendImportLinkModalOpen(true);
    }
  }, [customersCount, t]);

  const editParcel = useCallback(
    (id: GridRowId) => {
      const parcel = parcelsList.find((parcel) => parcel._id === id);
      setParcelToUpdate(parcel);
      setParcelInformationModalIsOpen(true);
    },
    [parcelsList],
  );

  const handleModalClose = useCallback(() => {
    setParcelToUpdate(undefined);
    setParcelInformationModalIsOpen(false);
  }, []);

  const closeSendImportLinkModal = useCallback(
    () => setIsSendImportLinkModalOpen(false),
    [],
  );

  const closeImportModal = useCallback(
    () => setIsImportParcelsModalOpen(false),
    [],
  );

  const toolbarProps = useMemo(
    () => ({
      addButton: (
        <AddParcelButton
          onCreateClick={onCreateClick}
          onImportClick={onImportClick}
          onSendImportLinkClick={onSendImportLinkClick}
        />
      ),
      onDeleteClick: handleTableRowsDelete,
    }),
    [
      handleTableRowsDelete,
      onCreateClick,
      onImportClick,
      onSendImportLinkClick,
    ],
  );

  const closeConfirmDialog = useCallback(
    () => setIsCreateCustomersModalOpen(false),
    [],
  );

  const approveConfirmDialog = useCallback(() => {
    navigate(`${ROUTES.MAIN.CUSTOMERS}`, {
      state: { create: true },
    });
  }, [navigate]);

  return (
    <>
      <BaseDataTable
        columnVisibilityModel={hiddenColumns[TABLE_TYPES.PARCELS_TABLE]}
        columns={columns}
        handlePagination={handleTablePagination}
        handleQuickSearch={handleTableSearch}
        loading={loading || tableLoading}
        maxCount={listMaxCount}
        onColumnVisibilityModelChange={onChangeColumnsView}
        onRowClick={editParcel}
        rows={parcelsList}
        toolbarProps={toolbarProps}
        paginationModel={{ page: listMeta.page, pageSize: listMeta.pageLength }}
      />
      <ImportParcelsModal
        isOpen={isImportParcelsModalOpen}
        onClose={closeImportModal}
      />
      <SendImportLinkModal
        isOpen={isSendImportLinkModalOpen}
        onClose={closeSendImportLinkModal}
      />
      <ConfirmDialog
        isOpen={isCreateCustomersModalOpen}
        onApprove={approveConfirmDialog}
        onClose={closeConfirmDialog}
        title={t("parcels.noCustomersAvailableModal.title")}
        text={createCustomersModalText}
        text_2={t("parcels.noCustomersAvailableModal.text2")}
      />
      {parcelInformationModalIsOpen && (
        <ParcelInformationModal
          isOpen={parcelInformationModalIsOpen}
          onClose={handleModalClose}
          parcel={parcelToUpdate}
        />
      )}
    </>
  );
};
