import { Coordinate } from "@ero/app-common/util/Coordinate";
import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Box } from "@mui/material";
import {
  CenterControlV2,
  FullScreenControlV2,
  MapV2,
  ZoomControlV2,
} from "ProjectComponents";
import { BottomControls } from "ProjectComponents/map/components/controls/bottomControls";
import { TopControls } from "ProjectComponents/map/components/controls/topControls";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { MapSearchField } from "./mapSearchField";
import { ParcelFigures } from "./parcelFigures";

interface ISelectParcelMap {
  parcels: ParcelsResponseBody["data"];
  selectedParcels: number[];
  handleSelect: (id: number) => void;
  showComponent: boolean;
  currentSearchQuery: string;
  handleSearch: (query: string) => void;
}

const isCoordinate = (
  coordinate: Coordinate | undefined | null,
): coordinate is Coordinate => {
  return coordinate !== undefined && coordinate !== null;
};

export const SelectParcelMap: React.FC<ISelectParcelMap> = ({
  parcels,
  selectedParcels,
  handleSelect,
  showComponent,
  currentSearchQuery,
  handleSearch,
}) => {
  const map = useRef<google.maps.Map>();
  const companyLocation = useSelector(
    (state: AppState) => state.auth.companyData.machinePosition,
  );

  const [zoom, setZoom] = useState<number>(16);

  useEffect(() => {
    if (showComponent && parcels.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      parcels
        .map((parcel) =>
          parcel.shape?.length ?? 0 > 0 ? parcel.shape : parcel.position,
        )
        .flat()
        .filter(isCoordinate)
        .forEach((coordinate) => {
          const latLng = new google.maps.LatLng(coordinate.lat, coordinate.lng);
          bounds.extend(latLng);
        });
      map.current?.fitBounds(bounds);
    }
  }, [companyLocation, parcels, showComponent]);

  const mapCenter = useMemo(() => companyLocation, [companyLocation]);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    map.current = mapInstance;
  }, []);

  const onZoomChanged = useCallback(
    () => {
      const currentZoom = map.current?.getZoom();
      if (currentZoom) setZoom(currentZoom);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [map],
  );

  const resetCenter = useCallback(() => {
    if (mapCenter) {
      map.current?.setCenter(mapCenter);
    }
  }, [mapCenter]);

  return (
    <Box height="85%" display={!showComponent ? "none" : undefined}>
      <MapV2
        id="parcel-selection-map"
        center={mapCenter}
        zoom={zoom}
        onLoad={onLoad}
        onZoomChanged={onZoomChanged}
      >
        <ParcelFigures
          zoom={zoom}
          parcels={parcels}
          itemOnClick={handleSelect}
          selectedParcels={selectedParcels}
        />
        <MapSearchField
          currentQuery={currentSearchQuery}
          onChange={handleSearch}
          parcelsFound={parcels.length}
        />
        <TopControls>
          <FullScreenControlV2 mapRef={map} />
        </TopControls>
        <BottomControls>
          <CenterControlV2 onCenter={resetCenter}></CenterControlV2>
          <ZoomControlV2 zoom={zoom} onZoomChanged={setZoom} />
        </BottomControls>
      </MapV2>
    </Box>
  );
};
