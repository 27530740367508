import { ServiceOutside } from "@ero/app-common/models";
import {
  UnitSymbol,
  convertAreaToCustom,
} from "@ero/app-common/util/convertArea";
import { numberAsCurrency } from "@ero/app-common/util/numberFormatter";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import {
  Edit,
  Engineering,
  Euro,
  Menu,
  PhotoSizeSelectSmall,
  Spa,
  TextSnippet,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { getServicesInitial } from "Store/services";
import { getBillingUnitNameByValue } from "Utils";
import { UpdateDialog } from "./components/updateDialog/updateDialog";
import { FIELDS } from "./helper";

interface IOverviewCard {
  job?: JobResponseBody;
}

export const OverviewCard: React.FC<IOverviewCard> = ({ job }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [fieldToUpdate, setFieldToUpdate] = useState<FIELDS | undefined>();

  const unitOfMeasurement = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const { listMeta, list: allServices } = useSelector(
    (state: AppState) => state.services,
  );

  useEffect(() => {
    dispatch(getServicesInitial(listMeta));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const service: ServiceOutside | undefined = useMemo(
    () =>
      allServices.filter(
        (service) => service._id === job?.originalService,
      )?.[0],
    [allServices, job?.originalService],
  );

  const billingUnitLabel = useMemo(
    () =>
      service?.billingUnit
        ? service?.cost !== 0
          ? getBillingUnitNameByValue(service.billingUnit, unitOfMeasurement)
          : "-"
        : "",
    [service?.billingUnit, service?.cost, unitOfMeasurement],
  );

  const costLabel = useMemo(
    () => (service?.cost ? numberAsCurrency(service.cost) : ""),
    [service?.cost],
  );

  const handleCloseUpdateModal = () => {
    setFieldToUpdate(undefined);
    setUpdateModalOpen(false);
  };
  const handleOpenUpdateModal = (field: FIELDS) => {
    setFieldToUpdate(field);
    setUpdateModalOpen(true);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List>
              <ListSubheader>Parzelle</ListSubheader>
              <ListItem disablePadding>
                <ListItemIcon>
                  <PhotoSizeSelectSmall />
                </ListItemIcon>
                <ListItemText
                  primary={
                    (job?.parcel.size
                      ? convertAreaToCustom(job?.parcel.size, unitOfMeasurement)
                      : "-") +
                    " " +
                    UnitSymbol[unitOfMeasurement]
                  }
                  secondary={t("general.fields.area")}
                />
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemIcon>
                  <Spa />
                </ListItemIcon>
                <ListItemText
                  primary={job?.parcel.crop.name ?? "-"}
                  secondary={t("general.fields.grapeVariety")}
                />
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemIcon>
                  <Menu />
                </ListItemIcon>
                <ListItemText
                  primary={job?.parcel.rowAmount ?? "-"}
                  secondary={t("general.fields.rowAmount")}
                />
              </ListItem>
              <ListSubheader>{t("general.service_one")}</ListSubheader>
              <ListItem
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleOpenUpdateModal(FIELDS.SERVICE)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <Engineering />
                </ListItemIcon>
                <ListItemText primary={job?.name} secondary={"Name"} />
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemIcon>
                  <Euro />
                </ListItemIcon>
                <ListItemText
                  primary={costLabel}
                  secondary={billingUnitLabel}
                ></ListItemText>
              </ListItem>
              <ListSubheader>{t("general.fields.notes")}</ListSubheader>
              <ListItem
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleOpenUpdateModal(FIELDS.NOTES)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <TextSnippet />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    overflow: "hidden",
                    maxWidth: 300,
                    textOverflow: "ellipsis",
                  }}
                >
                  {job?.notes || "-"}
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
      <UpdateDialog
        open={updateModalOpen}
        onClose={handleCloseUpdateModal}
        fieldToEdit={fieldToUpdate}
        job={job}
      />
    </Card>
  );
};
