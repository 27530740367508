import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

const useMachineKindOptions = () => {
  const [t] = useTranslation();
  return [
    {
      label: t("machines.kind.tractor"),
      value: 1,
    },
    {
      label: t("machines.kind.attachment"),
      value: 2,
    },
    {
      label: t("machines.kind.handheld"),
      value: 3,
    },
    {
      label: t("machines.kind.other"),
      value: 4,
    },
    {
      label: t("machines.kind.harvester"),
      value: 5,
    },
  ];
};
export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();
  const machineKindOptions = useMachineKindOptions();
  return [
    {
      field: "name",
      headerName: t("general.fields.name"),
    },
    {
      field: "manufacturer",
      headerName: t("machines.fields.manufacturer"),
      valueGetter: (params) => {
        if (params.value._id === -1) {
          return "";
        }
        return params.value.name;
      },
    },
    {
      field: "model",
      headerName: t("machines.fields.model"),
    },
    {
      field: "kind",
      headerName: t("general.fields.type"),
      valueGetter: (params) =>
        machineKindOptions.find((option) => option.value === params.value)
          ?.label,
    },
    {
      field: "licensePlate",
      headerName: t("machines.fields.licensePlate"),
    },
    {
      field: "intact",
      headerName: t("general.fields.status"),
      valueGetter: (params) =>
        params.value ? t("machines.state.active") : t("machines.state.broken"),
    },
    {
      field: "notes",
      headerName: t("general.fields.notes"),
    },
    {
      field: "nextMaintenance",
      headerName: t("machines.fields.nextMaintenance"),
      valueGetter: (params) =>
        params.value && new Date(params.value).toLocaleDateString(),
    },
    {
      field: "tuv",
      headerName: t("machines.fields.date"),
      valueGetter: (params) =>
        params.value && new Date(params.value).toLocaleDateString(),
    },
  ];
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "string", access: ["name"] },
  { type: "string", access: ["model"] },
  { type: "string", access: ["licensePlat"] },
  { type: "string", access: ["notes"] },
];
