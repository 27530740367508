import {
  changePassword,
  forgotPassword,
  getCompanyDetails,
  getProfileDetails,
  login,
  refreshToken,
  resetPassword,
  updateCompanyDetails,
  updateProfileDetails,
  verifyPassword,
} from "Api/auth";
import { errorToast, successToast } from "Services";
import {
  type ApiResponse,
  type CompanyType,
  type TokenType,
  type UserDataType,
} from "Types";
import { setSentryUser } from "Utils/sentry";
import i18n from "i18n";
import Cookies from "js-cookie";
import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import { closeWs, openWs } from "../general";
import {
  AUTH_ACTION_TYPES,
  type IChangeColorMode,
  type IChangeLanguage,
  type IChangePassword,
  type IForgotPasswordRequestAction,
  type ILoginRequestAction,
  type IResetPasswordRequestAction,
  type IUpdateCompanyData,
  type IUpdateUserData,
  type IVerifyPasswordRequestAction,
} from "./action-types";
import {
  loginSuccess,
  logoutSuccess,
  refreshAccessToken,
  resetMeta,
  setCompanyDetails,
  setError,
  setLanguage,
  setLoading,
  setResetToken,
  setSuccess,
  setUserData,
} from "./actions";

const loginAppDomain = process.env.REACT_APP_LOGIN_DOMAIN;

export function* initialAuthSaga() {
  try {
    const store = yield select();
    const authReducer = store.auth;
    let tokens = authReducer.tokens;

    const tokensJSON = Cookies.get("tokens");

    if (tokensJSON) {
      tokens = JSON.parse(tokensJSON);
    }

    if (!tokens) {
      throw new Error("no tokens");
    }

    const [{ data: userData }, { data: companyData }]: [
      ApiResponse<UserDataType>,
      ApiResponse<CompanyType>,
    ] = yield all([
      call(getProfileDetails, tokens.accessToken),
      call(getCompanyDetails, tokens.accessToken),
    ]);

    setSentryUser(userData);

    yield put(openWs());

    yield put(loginSuccess(tokens, userData, companyData));
  } catch (error) {
    yield call(logoutSaga);
  } finally {
    Cookies.remove("tokens", { domain: loginAppDomain });
  }
}

export function* loginSaga({ payload }: ILoginRequestAction) {
  const { credentials } = payload;

  try {
    yield put(setLoading());

    const { data }: ApiResponse<TokenType> = yield call(login, credentials);

    const [{ data: userData }, { data: companyData }]: [
      ApiResponse<UserDataType>,
      ApiResponse<CompanyType>,
    ] = yield all([
      call(getProfileDetails, data.accessToken),
      call(getCompanyDetails, data.accessToken),
    ]);

    setSentryUser(userData);

    yield put(openWs());
    yield put(loginSuccess(data, userData, companyData));

    yield put(setSuccess());

    // const determineTutorialRoute = (
    //   tutorialStep: number | undefined,
    // ): string => {
    //   switch (tutorialStep) {
    //     case -1:
    //       return ROUTES.MAIN.DASHBOARD.ROOT;
    //     default:
    //       return ROUTES.MISC.TUTORIAL;
    //   }
    // };

    // yield put(push(determineTutorialRoute(userData.tutorialStep)));
  } catch (error) {
    yield put(setError());

    errorToast(i18n.t("notification.wrongCredentials"));
  }
}

export function* logoutSaga() {
  setSentryUser(null);
  yield put(logoutSuccess());
  yield put(closeWs());

  // let redux-persist to sync with redux store before redirect
  yield delay(0);

  window.location.replace(`/login`);
}

export function* verifyPasswordSaga({ payload }: IVerifyPasswordRequestAction) {
  const { credentials } = payload;

  try {
    yield put(setLoading());

    const { data }: ApiResponse<TokenType> = yield call(
      verifyPassword,
      credentials,
    );

    yield put(setResetToken(data.resetToken));

    yield put(setSuccess());

    // yield put(push(ROUTES.AUTH.RESET_PASSWORD));
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* resetPasswordSaga({ payload }: IResetPasswordRequestAction) {
  const { passwordData } = payload;

  try {
    yield put(setLoading());

    yield call(resetPassword, passwordData);

    yield put(setSuccess());

    successToast(i18n.t("notification.passwordResetSuccess"));

    // yield put(push(ROUTES.AUTH.LOGIN));
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* forgotPasswordSaga({ payload }: IForgotPasswordRequestAction) {
  const { email } = payload;

  try {
    yield put(setLoading());

    yield call(forgotPassword, { userName: email });

    yield put(setSuccess());

    successToast(i18n.t("notification.forgotPasswordSuccess"));

    // yield put(push(ROUTES.AUTH.LOGIN));
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* updateUserDataSaga({ payload }: IUpdateUserData) {
  const { values } = payload;

  try {
    yield put(setLoading());

    const store = yield select();

    const { data: updatedUserData }: ApiResponse<UserDataType> = yield call(
      updateProfileDetails,
      values,
      store.auth.userData._id,
    );

    // add cache buster as backend returns new avatar url with the same file identifier
    updatedUserData.avatar =
      `${updatedUserData.avatar}&t=` + new Date().getTime();

    setSentryUser(updatedUserData);
    yield put(setUserData(updatedUserData));

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* updateCompanyDataSaga({ payload }: IUpdateCompanyData) {
  const { values } = payload;

  try {
    yield put(setLoading());

    const { data }: ApiResponse<CompanyType> = yield call(
      updateCompanyDetails,
      { ...values },
    );

    // add cache buster as backend returns new avatar url with the same file identifier
    data.avatar = `${data.avatar}&t=` + new Date().getTime();

    yield put(setCompanyDetails(data));

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* changeLanguageSaga({ payload }: IChangeLanguage) {
  const { lang } = payload;

  try {
    const store = yield select();

    const { data: updatedUserData } = yield call(
      updateProfileDetails,
      { language: lang },
      store.auth.userData._id,
    );

    setSentryUser(updatedUserData);

    yield put(setLanguage(lang));
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* changePasswordSaga({ payload }: IChangePassword) {
  const { data } = payload;

  try {
    yield put(setLoading());

    yield call(changePassword, data);

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* getUserDataSaga() {
  try {
    const store = yield select();
    const authReducer = store.auth;

    const { data: updatedUserData } = yield call(
      getProfileDetails,
      authReducer.tokens.accessToken,
    );

    setSentryUser(updatedUserData);

    yield put(setUserData(updatedUserData));
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* refreshTokenSaga() {
  const store = yield select();
  const authReducer = store.auth;

  const { data } = yield call(refreshToken, authReducer.tokens.refreshToken);

  yield put(refreshAccessToken(data.accessToken));
}

export function* changeColorModeSaga({ payload }: IChangeColorMode) {
  const { mode } = payload;

  try {
    Cookies.set("colorMode", mode, { domain: loginAppDomain });
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export default function* authSagas() {
  yield all([
    takeEvery(AUTH_ACTION_TYPES.INITIAL_REQUEST, initialAuthSaga),
    takeEvery(AUTH_ACTION_TYPES.LOGIN_REQUEST, loginSaga),
    takeEvery(AUTH_ACTION_TYPES.LOGOUT_REQUEST, logoutSaga),
    takeEvery(AUTH_ACTION_TYPES.VERIFY_PASS_REQUEST, verifyPasswordSaga),
    takeEvery(AUTH_ACTION_TYPES.RESET_PASS_REQUEST, resetPasswordSaga),
    takeEvery(AUTH_ACTION_TYPES.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga),
    takeEvery(AUTH_ACTION_TYPES.UPDATE_USERDATA, updateUserDataSaga),
    takeEvery(AUTH_ACTION_TYPES.UPDATE_COMPANY_DETAILS, updateCompanyDataSaga),
    takeEvery(AUTH_ACTION_TYPES.CHANGE_LANGUAGE, changeLanguageSaga),
    takeEvery(AUTH_ACTION_TYPES.CHANGE_PASSWORD, changePasswordSaga),
    takeEvery(AUTH_ACTION_TYPES.GET_USERDATA, getUserDataSaga),
    takeEvery(AUTH_ACTION_TYPES.CHANGE_COLORMODE, changeColorModeSaga),
  ]);
}
