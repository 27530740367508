import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Person } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { MachineSvg } from "Assets/icons";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IDriverMachineInfo {
  jobEmployees: JobResponseBody["employees"] | undefined;
  jobMachine: JobResponseBody["machine"] | undefined;
}

export const DriverMachineInfo: React.FC<IDriverMachineInfo> = ({
  jobEmployees,
  jobMachine,
}) => {
  const [t] = useTranslation();

  const [driverName, driverAvatar] = useMemo(() => {
    if (!jobEmployees?.length) {
      return [
        t("orders.createModal.noEmployees"),
        <Person key="no-driver-avatar" />,
      ];
    }
    return [
      jobEmployees[0].firstName
        ? jobEmployees[0].firstName + " " + jobEmployees[0].lastName
        : jobEmployees[0].userName,
      <Avatar
        key={`driver-avatar-${jobEmployees[0]._id}`}
        alt={jobEmployees[0].userName.toUpperCase()}
        src={jobEmployees[0].avatar}
      />,
    ];
  }, [jobEmployees, t]);

  const [machineName, machineAvatar] = useMemo(() => {
    if (!jobMachine?.length) {
      return [
        t("orders.createModal.noMachines"),
        <MachineSvg key="no-machine-avatar" />,
      ];
    }
    return [
      jobMachine[0].name,
      <Avatar
        key={`machine-avatar-${jobMachine[0]._id}`}
        alt={jobMachine[0].name.toUpperCase()}
        src={jobMachine[0].avatar}
      />,
    ];
  }, [jobMachine, t]);

  return (
    <>
      <ListItem disablePadding>
        <ListItemAvatar>{driverAvatar}</ListItemAvatar>
        <ListItemText
          primary={driverName}
          secondary={t("employees.permission.driver")}
        />
      </ListItem>
      <Divider />
      <ListItem disablePadding>
        <ListItemAvatar>{machineAvatar}</ListItemAvatar>
        <ListItemText
          primary={machineName}
          secondary={t("general.labels.machine")}
        />
      </ListItem>
    </>
  );
};
