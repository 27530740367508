import { CARDINAL_DIRECTION } from "@ero/app-common/enums/cardinalDirection";
import { LANGUAGE } from "@ero/app-common/enums/language";
import { STAIN } from "@ero/app-common/enums/stain";
import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { numberFormatterWithDefault } from "@ero/app-common/util/numberFormatter";
import Chip from "@mui/material/Chip";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { LocalParcelType } from "Store/parcels/specs";
import { displaySizeOrFallback as displaySize } from "Utils";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const orientation = Object.entries(CARDINAL_DIRECTION).map(
  ([label, value]) => ({
    label,
    value,
  }),
);

export const useColumns = (
  unitOfMeasurement: number,
): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();
  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("general.fields.name"),
      },
      {
        field: "customer",
        headerName: t("general.fields.customer"),
        valueGetter: (params) =>
          params.value._id !== -1 ? params.value.companyName : "",
      },
      {
        field: "crop",
        headerName: t("parcels.fields.crop"),
        valueGetter: (params) =>
          params.value._id !== -1 ? params.value.name : "",
      },
      {
        field: "rowAmount",
        headerName: t("parcels.fields.rowAmount"),
        valueGetter: (params) =>
          numberFormatterWithDefault(params.row.rowAmount, ""),
      },
      {
        field: "rowGap",
        headerName: t("parcels.fields.rowGap"),
        valueGetter: (params) =>
          numberFormatterWithDefault(params.row.rowGap, ""),
      },
      {
        field: "size",
        headerName: t("parcels.fields.sizeUnit", {
          unit: UnitSymbol[unitOfMeasurement],
        }),
        valueGetter: (params) =>
          displaySize(params.row.size, unitOfMeasurement, "", false),
      },
      {
        field: "plantAmount",
        headerName: t("general.fields.plantAmount"),
        valueGetter: (params) =>
          numberFormatterWithDefault(params.row.plantAmount, ""),
      },
      {
        field: "plantGap",
        headerName: t("general.fields.plantGap"),
        valueGetter: (params) =>
          numberFormatterWithDefault(params.row.plantGap, ""),
      },
      {
        field: "largeRegion",
        headerName: t("parcels.fields.regions"),
      },
      {
        field: "growingarea",
        headerName: t("parcels.fields.growingarea"),
      },
      {
        field: "separatedregion",
        headerName: t("parcels.fields.separatedregion"),
      },
      {
        field: "regionette",
        headerName: t("parcels.fields.regionette"),
      },
      {
        field: "mark",
        headerName: t("parcels.fields.mark"),
      },
      {
        field: "flurstuecke",
        headerName: t("general.labels.plot"),
        renderCell: (params) => (
          <>
            {params.value?.map(
              (flurstueck: LocalParcelType["flurstuecke"][0]) => (
                <Chip
                  size="small"
                  style={{ marginRight: 3 }}
                  key={flurstueck._id}
                  label={`${flurstueck.flurstuecksnummer}`}
                ></Chip>
              ),
            )}
          </>
        ),
        valueFormatter: (params) =>
          params.value
            ?.map((flurstueck) => flurstueck.flurstuecksnummer)
            .join(", "),
      },
      {
        field: "stain",
        headerName: t("parcels.fields.stain"),
        filterable: false,
        valueGetter: (params) => {
          switch (params.value) {
            case STAIN.NONE:
              return t("parcels.stain.none");
            case STAIN.WEAK:
              return t("parcels.stain.weak");
            case STAIN.STRONG:
              return t("parcels.stain.strong");
            default:
              return "-";
          }
        },
      },
      {
        field: "clone",
        headerName: t("parcels.fields.clone"),
      },
      {
        field: "base",
        headerName: t("parcels.fields.base"),
      },
      {
        field: "plantingDate",
        headerName: t("parcels.fields.plantingDate"),
        valueFormatter: (params) => {
          if (!params.value) {
            return undefined;
          }
          const date = new Date(params.value);
          return date.toLocaleDateString(LANGUAGE.DE);
        },
      },
      {
        field: "slope",
        headerName: t("parcels.fields.steepSlope"),
      },
      {
        field: "slopeDirection",
        headerName: t("parcels.fields.orientation"),
        valueGetter: (params) => {
          const directionOption = orientation.find(
            (option) => option.value === params.value,
          );
          return directionOption
            ? t(`parcels.slopeDirection.${directionOption.label}`)
            : undefined;
        },
      },
      {
        field: "trainingType",
        headerName: t("parcels.fields.formOfEducation"),
        valueGetter: (params) =>
          params.value?._id !== -1 ? params.value?.name : "",
      },
      {
        field: "notes",
        headerName: t("general.fields.notes"),
      },
    ],
    [unitOfMeasurement, t],
  );
  return columns;
};

export const defaultHiddenColumns = {
  plantAmount: false,
  plantGap: false,
  rowAmount: false,
  rowGap: false,
  separatedregion: false,
  largeRegion: false,
  growingarea: false,
  clone: false,
  base: false,
  slope: false,
  trainingType: false,
  color: false,
  stain: false,
  plantingDate: false,
  slopeDirection: false,
  notes: false,
};

export const defaultHiddenColumnsCreateOrder = {
  customer: false,
  plantAmount: false,
  plantGap: false,
  rowGap: false,
  separatedregion: false,
  largeRegion: false,
  growingarea: false,
  clone: false,
  base: false,
  slope: false,
  trainingType: false,
  color: false,
  stain: false,
  plantingDate: false,
  slopeDirection: false,
  notes: false,
  mark: false,
  flurstuecke: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "string", access: ["name"] },
  { type: "number", access: ["rowAmount"], default: "-" },
  { type: "number", access: ["rowGap"], default: "-" },
  { type: "number", access: ["planAmount"], default: "-" },
  { type: "number", access: ["plantGap"], default: "-" },
  { type: "number", access: ["size"], default: "-" },
  { type: "string", access: ["separatedregion"] },
  { type: "string", access: ["growingarea"] },
  { type: "string", access: ["largeregion"] },
  { type: "string", access: ["regionette"] },
  { type: "string", access: ["mark"] },
  { type: "string", access: ["clone"] },
  { type: "string", access: ["base"] },
  { type: "string", access: ["notes"] },
];
