import { MACHINEKIND } from "@ero/app-common/enums/MACHINEKIND";
import Container from "@mui/material/Container/Container";
import Grid from "@mui/material/Grid/Grid";
import { AvatarUpload, Input } from "Components";
import { useDropdownValues } from "Hooks/dropdownValues";
import { SelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/SelectWithAutoComplete";
import { StaticMultiSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticMultiSelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";

import { DateInput } from "ProjectComponents/FormikDateInput/DateInput";
import { ValuesType } from "ProjectComponents/machineInformationModal/formConfig";
import { resizeTo } from "Utils";
import { useMachineDropdownOptions } from "Utils/machines";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

export const FormContent: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ValuesType>();
  const [t] = useTranslation();

  const { kindOptions, alignmentOptions, statusOptions } =
    useMachineDropdownOptions();

  const { fetchDropdownValues: fetchManufacturerOptions } =
    useDropdownValues("manufacturer");

  const handleAvatarUpload = (value: File) => {
    const resizedImage = resizeTo(value, 150);
    resizedImage.then((image: string) => {
      setFieldValue("avatar", image);
    });
  };

  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AvatarUpload
            name="machine"
            letterAvatar={values.name?.[0] ?? "M"}
            image={values.avatar}
            onSubmit={handleAvatarUpload}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Input
                name="name"
                type="text"
                label={t("machines.fields.name")}
                mandatory={true}
              />
            </Grid>
            <Grid item xs={12}>
              <StaticSelectWithAutoComplete
                name="kind"
                required={true}
                label={t("machines.fields.kind")}
                disableClearable
                options={kindOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectWithAutoComplete
                name="manufacturer"
                label={t("machines.fields.manufacturer")}
                disableClearable
                fetchOptions={fetchManufacturerOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="model"
                type="text"
                label={t("machines.fields.model")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="serialNumber"
                type="text"
                label={t("machines.fields.serialNumber")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="licensePlate"
                type="text"
                label={t("machines.fields.licensePlate")}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="features"
                type="text"
                label={t("machines.fields.features")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DateInput
                name="nextMaintenance"
                label={t("machines.fields.nextMaintenance")}
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput name="tuv" label={t("machines.fields.date")} />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="maintenanceInfo"
                type="text"
                label={t("machines.fields.maintenanceInfo")}
              />
            </Grid>
            <Grid item xs={6}>
              <StaticMultiSelectWithAutoComplete
                name="alignment"
                options={alignmentOptions}
                label={t("machines.fields.alignment")}
                disabled={
                  values.kind !== MACHINEKIND.ATTACHMENT &&
                  values.kind !== MACHINEKIND.TRACTOR &&
                  values.kind !== MACHINEKIND.OTHER
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="loadingCapacity"
                disabled={values.kind === MACHINEKIND.HANDHELD}
                type="number"
                label={t("machines.fields.loadingCapacity")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="length"
                disabled={values.kind === MACHINEKIND.HANDHELD}
                type="number"
                label={t("machines.fields.length")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="height"
                disabled={values.kind === MACHINEKIND.HANDHELD}
                type="number"
                label={t("machines.fields.height")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="width"
                disabled={values.kind === MACHINEKIND.HANDHELD}
                type="number"
                label={t("machines.fields.width")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="workingWidth"
                type="number"
                label={t("machines.fields.workingWidth")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="performance"
                type="number"
                disabled={values.kind === 3}
                label={t("machines.fields.performance")}
              />
            </Grid>
            <Grid item xs={6}>
              <StaticSelectWithAutoComplete
                name="intact"
                label={t("machines.fields.status")}
                disableClearable
                options={statusOptions}
                required={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Input
            name="notes"
            type="text"
            multiline={true}
            rows={20}
            label={t("machines.fields.notes")}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
