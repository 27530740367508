import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListSubheader,
} from "@mui/material";
import { Loader } from "Components";
import { AppState } from "Store";
import React from "react";
import { useSelector } from "react-redux";
import { StatusesList } from "./components/statusesList/statusesList";
import { TelemetryList } from "./components/telemetryList/telemetryList";

interface ITelemetryCard {
  jobId: number;
  statusOperations: JobResponseBody["statusOperations"];
}

export const TelemetryCard: React.FC<ITelemetryCard> = ({
  jobId,
  statusOperations,
}) => {
  const { loading } = useSelector(
    (state: AppState) => state.orderDetails.jobTelemetryStatus,
  );

  return (
    <Card>
      <CardContent>
        <List>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <ListSubheader>Statusoperationen</ListSubheader>
              <StatusesList statusOperations={statusOperations} />
            </Grid>
            <Grid item xs={12}>
              <ListSubheader>Telemetriewerte</ListSubheader>
              <TelemetryList jobId={jobId} />
            </Grid>
          </Grid>
        </List>
      </CardContent>
      {loading && (
        <Box position={"absolute"} top={0} width={"100%"} height={"100%"}>
          <Loader />
        </Box>
      )}
    </Card>
  );
};
