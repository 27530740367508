import { Grid } from "@mui/material";
import { TimeInput } from "ProjectComponents/FormikDateInput/TimeInput";
import React from "react";
import { useTranslation } from "react-i18next";

export const DayTimeContent: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item xs={6}>
        <TimeInput
          name="dateRestrictions.dayTime.startTime"
          required
          label={t("orders.createModal.startTime")}
        />
      </Grid>
      <Grid item xs={6}>
        <TimeInput
          name="dateRestrictions.dayTime.endTime"
          required
          label={t("orders.createModal.endTime")}
        />
      </Grid>
    </Grid>
  );
};
