import { BILLINGUNIT } from "@ero/app-common/enums/BILLINGUNIT";
import { ServiceOutside } from "@ero/app-common/models/Service";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import Container from "@mui/material/Container/Container";
import Grid from "@mui/material/Grid/Grid";
import { Input } from "Components/controls/input";
import { useDropdownValues } from "Hooks/dropdownValues";
import { SelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/SelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { AppState } from "Store/store";
import { OptionType } from "Types";
import { useBillingUnitOptions } from "Utils";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MaskedInput from "react-text-mask";

export const BasicData: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ServiceOutside>();
  const [t] = useTranslation();

  const { fetchDropdownValues: fetchServiceKindOptions } =
    useDropdownValues("serviceKind");

  const { unitOfMeasurement } = useSelector(
    (store: AppState) => store.auth.companyData,
  );

  const billingUnitOptions = useBillingUnitOptions(unitOfMeasurement);

  const TimeMaskCustom = useCallback((props) => {
    const { inputRef: _, ...other } = props;

    return (
      <MaskedInput
        {...other}
        mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
        placeholder={"hh:mm:ss"}
        showMask
      />
    );
  }, []);

  return (
    <Container maxWidth={"lg"}>
      <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input
                  name="name"
                  mandatory={true}
                  type="text"
                  label={t("services.fields.name")}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="additionalName"
                  type="text"
                  label={t("services.fields.additionalName")}
                />
              </Grid>
              <Grid item xs={6}>
                <StaticSelectWithAutoComplete
                  name="billingUnit"
                  options={billingUnitOptions}
                  label={t("services.fields.billingUnit")}
                  onChange={(value: OptionType | null) => {
                    if (value !== null && value?.value === BILLINGUNIT.NONE) {
                      setFieldValue("cost", 0);
                    }
                  }}
                  disableClearable
                  required={true}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name="cost"
                  type="number"
                  label={t("services.fields.costUnit")}
                  disabled={values.billingUnit === BILLINGUNIT.NONE}
                  mandatory
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  inputProps={{ inputComponent: TimeMaskCustom }}
                  size="medium"
                  name="difficulty"
                  type="text"
                  label={t("services.fields.areaPerformance", {
                    unit: UnitSymbol[unitOfMeasurement],
                  })}
                  placeholder="hh:mm:ss"
                  mandatory
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="description"
                  type="text"
                  label={t("services.fields.description")}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectWithAutoComplete
                  name="serviceKind"
                  fetchOptions={fetchServiceKindOptions}
                  required={true}
                  disableClearable
                  label={t("services.fields.serviceKind")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Input label={t("general.fields.notes")} multiline name="notes" />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
