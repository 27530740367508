import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Event } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListSubheader,
} from "@mui/material";
import { ROUTES } from "Constants";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { DriverMachineInfo } from "./components/driverMachineInfo/driverMachineInfo";
import { TimeInfo } from "./components/timeInfo/timeInfo";

interface IPlanningCard {
  job: JobResponseBody | undefined;
}

export const PlanningCard: React.FC<IPlanningCard> = ({ job }) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const navigateToPlanning = useCallback(() => {
    const state: {
      initialDate?: number;
      scrollTime?: number;
      orderId?: number;
    } = {};
    if (job?.start !== -1 && job?.end !== -1) {
      state.initialDate = job?.start;
      state.scrollTime = job?.start;
    } else {
      state.orderId = job.order;
    }
    navigate(`${ROUTES.MAIN.PLANNING}`, {
      state,
    });
  }, [job?.end, job?.order, job?.start, navigate]);

  return (
    <Card>
      <CardContent>
        <List>
          <ListSubheader>Bearbeitet von</ListSubheader>
          <DriverMachineInfo
            jobEmployees={job?.employees}
            jobMachine={job?.machine}
          />
          <ListSubheader> {t("general.labels.time")}</ListSubheader>
          <TimeInfo jobStart={job?.start} jobEnd={job?.end} />
        </List>
      </CardContent>
      <CardActions>
        <Button onClick={navigateToPlanning} startIcon={<Event />}>
          {t("components.calendar.reschedule")}
        </Button>
      </CardActions>
    </Card>
  );
};
