import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import React from "react";
import { useTranslation } from "react-i18next";

export const YouTubeModal: React.FC<{
  open: boolean;
  onClose: () => void;
  videoUrl: string;
}> = ({ open, onClose, videoUrl }) => {
  const [t] = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>{t("parcels.upload.videoModalTitle")}</DialogTitle>
      <CloseButton onClose={onClose} />
      <DialogContent sx={{ p: 0 }}>
        <Box
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
          alignItems="center"
          sx={{ p: 0, height: 315, overflow: "hidden" }}
        >
          <iframe
            width="560"
            height="315"
            src={videoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
