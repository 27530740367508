import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Box, List, Typography } from "@mui/material";
import { ConfirmDialog, Loader } from "Components";
import { type AppState } from "Store";
import { deleteJob, getJobTelemtry } from "Store/orderDetails";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { JobDetailsModal } from "../jobDetailsModal";
import { JobItem } from "./jobItem";

interface IJobsList {}

export const JobsList: React.FC<IJobsList> = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation();
  const jobWrapperRef = useRef<HTMLDivElement>(null);

  const { state } = useLocation();

  const [selectedJobId, setSelectedJobId] = useState<number>();
  const [jobIdForDeletion, setJobIdForDeletion] = useState<number>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] =
    useState<boolean>(false);

  const { order, loading } = useSelector(
    (state: AppState) => state.orderDetails,
  );
  const jobs = useSelector(
    (state: AppState) => state.orderDetails.order?.jobDetails?.jobs,
  );

  const showJobDetails = useCallback(
    (id: number) => {
      setSelectedJobId(id);
      dispatch(getJobTelemtry(id));
      setIsJobDetailsModalOpen(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.jobDetails?.jobs],
  );

  const onClick = useCallback(
    (_: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
      showJobDetails(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.jobDetails?.jobs],
  );

  const onDelete = useCallback((item: JobResponseBody) => {
    setJobIdForDeletion(item._id);
    setIsConfirmModalOpen(true);
  }, []);

  const handleDeleteApprove = useCallback(() => {
    dispatch(deleteJob(jobIdForDeletion!));
    setIsConfirmModalOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobIdForDeletion]);

  useEffect(() => {
    if (state?.jobId) {
      showJobDetails(state.jobId);
    }
  }, [state, showJobDetails]);

  return (
    <>
      <Box padding={1} textAlign="center" sx={{ backgroundColor: "#3d3d3d" }}>
        <Typography variant="button" color="#fff">
          {t("general.navigation.jobs")} {jobs?.length && `(${jobs.length})`}
        </Typography>
      </Box>
      <Box ref={jobWrapperRef} height="100%" overflow="auto">
        <List component="nav" sx={{ paddingBottom: 15 }}>
          <>
            {jobs?.map((job) => (
              <JobItem
                job={job}
                key={job._id}
                onClick={onClick}
                onDelete={onDelete}
              />
            ))}
          </>
        </List>
        {!jobs?.length && (
          <Typography variant="button" component={"div"} textAlign={"center"}>
            {t("orders.details.jobDetails.noJobs")}
          </Typography>
        )}
        {loading && (
          <div>
            <Loader />
          </div>
        )}
      </Box>
      <ConfirmDialog
        isOpen={isConfirmModalOpen}
        onApprove={handleDeleteApprove}
        onClose={() => setIsConfirmModalOpen(false)}
      />
      <JobDetailsModal
        isOpen={isJobDetailsModalOpen}
        onClose={() => setIsJobDetailsModalOpen(false)}
        jobId={selectedJobId}
      ></JobDetailsModal>
    </>
  );
};
