import { UNIT } from "@ero/app-common/util/Units";
import { Grid, TextField, Typography } from "@mui/material";
import { OneFieldForm } from "Components/oneFieldForm/oneFieldForm";
import { OneFieldFormSelect } from "Components/oneFieldForm/oneFieldFormSelect";
import { type AppState } from "Store";
import { updateCompanyData } from "Store/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getValidationRules } from "./validationConfig";

export const CompanyInformation: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { userData, companyData, success } = useSelector(
    (state: AppState) => state.auth,
  );
  const validationRules = getValidationRules();

  const onSubmit = (values: Record<string, string>) => {
    dispatch(updateCompanyData(values));
  };

  const options = [
    {
      value: UNIT.SQUARE_METER,
      label: t("profile.measurementUnit.squareMeter"),
    },
    {
      value: UNIT.AR,
      label: t("profile.measurementUnit.ar"),
    },
    {
      value: UNIT.HEKTAR,
      label: t("profile.measurementUnit.ha"),
    },
  ];

  const unitOfMeasurement = !companyData.unitOfMeasurement
    ? undefined
    : companyData.unitOfMeasurement;

  return (
    <Grid container spacing={4} maxWidth={"lg"}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {t("profile.companyInformation.title")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <OneFieldForm
          name="name"
          value={companyData.contactData.name}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.companyName")}
          success={success}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={`#${userData._id}`}
          label={t("profile.companyInformation.eroCustomerNumber")}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <OneFieldForm
          name="street"
          value={companyData.contactData.address.street}
          success={success}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.street")}
        />
      </Grid>
      <Grid item xs={6}>
        <OneFieldForm
          name="taxId"
          value={companyData.contactData.imprint.taxId}
          success={success}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.vatId")}
        />
      </Grid>
      <Grid item xs={6}>
        <OneFieldForm
          name="zipCode"
          value={companyData.contactData.address.zipCode}
          success={success}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.zipCode")}
        />
      </Grid>
      <Grid item xs={6}>
        <OneFieldForm
          name="city"
          value={companyData.contactData.address.city}
          success={success}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("profile.companyInformation.city")}
        />
      </Grid>
      <Grid item xs={6}>
        <OneFieldFormSelect
          name="unitOfMeasurement"
          value={unitOfMeasurement?.toString()}
          success={success}
          options={options}
          onSubmit={onSubmit}
          validationRules={validationRules.unitOfMeasurement}
          label={t("profile.measurementUnit.title")}
        />
      </Grid>
    </Grid>
  );
};
