import { SearchProperty } from "@ero/app-common/util/SearchSpec";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import { SelectionTable } from "ProjectComponents/tableV2/selectionTable/SelectionTable";
import { AppState } from "Store";
import { getMachines, getMachinesInitial } from "Store/machines";
import { assignMachine, assignMachineStatus, resetMeta } from "Store/planning";
import { searchSpecShorthand } from "Utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useColumns } from "./tableConfig";

interface ISelectMachineModal {
  isOpen: boolean;
  onClose: () => void;
  plannedJob?: JobResponseBody;
}

export const SelectMachineModal: React.FC<ISelectMachineModal> = ({
  isOpen,
  onClose,
  plannedJob,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { assignMachineStatus: status } = useSelector(
    (state: AppState) => state.planning,
  );

  const {
    initialMeta,
    listMeta,
    list,
    maxCount,
    listUpdateLoading: tableLoading,
    searchData,
    filters,
  } = useSelector((state: AppState) => state.machines);

  const [selectedMachine, setSelectedMachine] = useState<number | undefined>();

  const submit = useCallback(
    () => {
      if (plannedJob && selectedMachine) {
        dispatch(assignMachine(plannedJob._id, selectedMachine));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plannedJob, selectedMachine],
  );

  useEffect(() => {
    if (status.success) {
      onClose();
      setSelectedMachine(undefined);
      dispatch(assignMachineStatus(false, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.success, onClose]);

  useEffect(() => {
    if (!list.length) {
      dispatch(getMachinesInitial(listMeta));
    }

    return () => {
      dispatch(resetMeta());
      dispatch(assignMachineStatus(false, false));
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.length, listMeta]);

  const handleTablePagination = useCallback(
    (currentPage: number, numberOfRows: number) => {
      dispatch(
        getMachines({
          params: {
            ...listMeta,
            page: currentPage,
            pageLength: numberOfRows,
          },
          search: searchData,
          filters,
        }),
      );
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta, searchData, filters],
  );

  const handleTableRowSelect = useCallback((ids: GridRowId[]) => {
    if (ids.length !== 1) {
      return;
    }
    setSelectedMachine(ids[0] as number);
  }, []);

  const handleTableSearch = useCallback(
    (query: string, properties?: SearchProperty[]) => {
      const config = {
        params: listMeta,
        search: searchSpecShorthand(query, properties),
      };

      dispatch(getMachines(config));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta],
  );

  const columns = useColumns();

  const selectionModal = useMemo(
    () => (selectedMachine ? [selectedMachine] : []),
    [selectedMachine],
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {t("components.calendar.selectMachineModal.title")}
      </DialogTitle>
      <DialogContent>
        <SelectionTable
          columns={columns}
          handlePagination={handleTablePagination}
          handleQuickSearch={handleTableSearch}
          loading={tableLoading || initialMeta.loading}
          maxCount={maxCount}
          onRowSelectionModelChange={handleTableRowSelect}
          rowSelectionModel={selectionModal}
          rows={list}
          singleSelect
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("components.calendar.selectMachineModal.cancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={selectedMachine === undefined}
          onClick={submit}
        >
          {t("general.buttons.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
