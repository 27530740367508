import { ParcelHistoryResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import {
  ButtonBase,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ROUTES } from "Constants/routes";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LocalParcelType } from "Store/parcels/specs";
import { HistoryItem } from "./historyItem";

interface IProps {
  historyItems: ParcelHistoryResponseBody["jobHistory"] | undefined;
  info: Partial<LocalParcelType>["jobInfo"];
}

export const HistoryList: React.FC<IProps> = ({
  historyItems,
  info,
}: IProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleGoToJob = useCallback(
    (item: ParcelHistoryResponseBody["jobHistory"][0], index: number): void => {
      const route = [
        ROUTES.MAIN.ORDERS,
        "/",
        info?.[index].order,
        ROUTES.MAIN.ORDER_DETAILS.JOBS,
        "/",
        item._id,
      ].join("");
      navigate(route);
    },
    [info, navigate],
  );

  const historyItemsList = useMemo(() => {
    const renderHistoryItem = (
      item: ParcelHistoryResponseBody["jobHistory"][0],
      index: number,
    ) => {
      return (
        <>
          <ButtonBase
            onClick={() => handleGoToJob(item, index)}
            key={`bb${item._id}`}
          >
            <HistoryItem item={item} key={`hi${item._id}`} />
          </ButtonBase>
          {index < (historyItems?.length ?? 0) - 1 && (
            <Divider sx={{ my: 2 }} />
          )}
        </>
      );
    };

    return (
      (historyItems?.length ?? 0) > 0 && (
        <Stack>
          {historyItems?.map(
            (item: ParcelHistoryResponseBody["jobHistory"][0], index: number) =>
              renderHistoryItem(item, index),
          )}
        </Stack>
      )
    );
  }, [handleGoToJob, historyItems]);

  return (
    <Container sx={{ minHeight: "40vh" }}>
      {historyItemsList}
      {historyItems?.length == 0 && (
        <Grid item sx={{ textAlign: "center", marginTop: "40px" }} xs={12}>
          <Typography variant={"body1"}>
            {t("parcels.jobHistory.noJobsAvailable")}
          </Typography>
        </Grid>
      )}
    </Container>
  );
};
