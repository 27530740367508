import { buffer, Feature, MultiPolygon, Polygon, union } from "@turf/turf";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MERGE_BUFFER_DISTANCE } from "../../utils";

const isPolygonFeature = (
  polygon: Feature<Polygon | MultiPolygon> | null,
): polygon is Feature<Polygon> =>
  polygon !== null && polygon.geometry.type === "Polygon";

export const useMergePolygons = () => {
  const [t] = useTranslation();

  const merge = useMemo(
    () =>
      (polygons: Feature<Polygon>[]): Feature<Polygon> => {
        const merged: Feature<Polygon> = polygons
          // extend polygon with buffer to be able to merge polygons with minimal distance but no actual overlap
          .map((polygon) =>
            buffer(polygon, MERGE_BUFFER_DISTANCE, {
              units: "meters",
            }),
          )
          // merge one after another
          .reduce((acc, polygon) => {
            const result = union(acc, polygon);
            if (!isPolygonFeature(result)) {
              throw new Error("Unable to merge parcels", {
                cause: t(
                  "notification.error.polygonMerging.cause.notContiguous",
                ),
              });
            }
            return result;
          }, polygons[0]);

        return buffer(merged, -MERGE_BUFFER_DISTANCE, { units: "meters" });
      },
    [t],
  );

  return merge;
};
