import {
  UnitSymbol,
  convertAreaToCustom,
} from "@ero/app-common/util/convertArea";
import { type ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { DirectionsOutlined, InfoOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AppState } from "Store";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface IParcelInfoWindowV2 {
  parcel: Partial<ParcelResponseBody> & { _id: number };
  handleEdit: (
    type: "parcel",
    parcel: number,
    orderId: number | undefined,
  ) => void;
  jobsIconOnClick?: (parcel: Partial<ParcelResponseBody>) => void;
  onNavigateToParcel?: (parcel: Partial<ParcelResponseBody>) => void;
}

export const ParcelInfoWindowV2: React.FC<IParcelInfoWindowV2> = ({
  parcel,
  handleEdit,
  onNavigateToParcel,
}) => {
  const [t] = useTranslation();
  const { unitOfMeasurement } = useSelector(
    (state: AppState) => state.auth.companyData,
  );

  const onNavigateClick = useCallback(() => {
    if (onNavigateToParcel) {
      onNavigateToParcel(parcel);
    }
  }, [onNavigateToParcel, parcel]);

  return (
    <Box width={350} overflow="hidden" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
      <Grid container>
        <Grid item container xs={11}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom={true}>
              {parcel.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {`${t("general.fields.customer")}: ${
                parcel.customer?.companyName || ""
              }`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {`${t("general.fields.area")}:
                  ${
                    (parcel.size &&
                      convertAreaToCustom(parcel.size!, unitOfMeasurement)) ||
                    " - "
                  }
                ${UnitSymbol[unitOfMeasurement]}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {`${t("general.fields.grapeVariety")}: ${
                parcel.crop?.name || ""
              }`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {`${t("parcels.fields.rowAmount")}: ${parcel.rowAmount || ""}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={1}
          direction="column"
          justifyContent="flex-start"
        >
          <Grid item>
            <Tooltip
              title={t("general.buttons.showParcelInformation") as string}
            >
              <IconButton
                size="small"
                onClick={() => handleEdit("parcel", parcel._id, undefined)}
                sx={{ color: "rgba(0, 0, 0, 0.87)" }}
              >
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </Grid>

          {typeof onNavigateToParcel === "function" && (
            <Grid item>
              <IconButton onClick={onNavigateClick}>
                <DirectionsOutlined />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
