import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { CalendarEventColor } from "Enums";
import { type CalendarDateRange, type UpdateCalendarJobType } from "Types";
import {
  PLANNING_ACTION_TYPES,
  type IAddCalendarJob,
  type IAssignMachine,
  type IAssignMachineStatus,
  type IFilterOrders,
  type IFilterOrdersSuccess,
  type IGetInitialCalendarErrorAction,
  type IGetInitialCalendarRequestAction,
  type IGetInitialCalendarSuccessAction,
  type IGetJobs,
  type IGetOrders,
  type IPrintEmployeeCalendar,
  type IRemoveCalendarJob,
  type IRescheduleJobs,
  type IRescheduleJobsSuccess,
  type IResetMetaAction,
  type ISetCalendarCurrentView,
  type ISetCalendarCurrentViewRange,
  type ISetErrorAction,
  type ISetFilters,
  type ISetJobs,
  type ISetLoadingAction,
  type ISetOrders,
  type ISetRowScale,
  type ISetSelectedEmployees,
  type ISetStatusColor,
  type ISetSuccessAction,
  type IUpdateJob,
  type IUpdateJobLoading,
  type IUpdateJobLock,
  type IUpdateJobSuccess,
  type IUpdateMultipleJobs,
  type IUpdateMultipleJobsSuccess,
} from "./action-types";
import { type LocalJobType } from "./specs";
import { FilterType } from "./types";

export const setLoading = (): ISetLoadingAction => ({
  type: PLANNING_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: PLANNING_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: PLANNING_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: PLANNING_ACTION_TYPES.RESET_META,
});

export const getCalendarInitial = (
  jobsDateRange: CalendarDateRange,
  ordersDateRange?: CalendarDateRange,
): IGetInitialCalendarRequestAction => {
  return {
    type: PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_REQUEST,
    payload: { jobsDateRange, ordersDateRange },
  };
};

export const getCalendarInitialSuccess = (
  orders: OrderResponseBody[],
  jobs: JobResponseBody[],
  employees: UserResponseBody[],
  dateRange: CalendarDateRange,
): IGetInitialCalendarSuccessAction => ({
  type: PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_SUCCESS,
  payload: { orders, jobs, employees, dateRange },
});

export const getCalendarInitialError = (
  dateRange: CalendarDateRange,
): IGetInitialCalendarErrorAction => ({
  type: PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_ERROR,
  payload: { dateRange },
});

export const getOrders = (): IGetOrders => ({
  type: PLANNING_ACTION_TYPES.GET_ORDERS,
});

export const setOrders = (values: OrderResponseBody[]): ISetOrders => ({
  type: PLANNING_ACTION_TYPES.SET_ORDERS,
  payload: values,
});

export const getJobs = ({
  requestDates,
  dateRange,
  replace,
}: {
  requestDates: CalendarDateRange;
  dateRange: CalendarDateRange;
  replace?: boolean;
}): IGetJobs => ({
  type: PLANNING_ACTION_TYPES.GET_JOBS,
  payload: { requestDates, dateRange, replace },
});

export const setJobs = (
  jobs: JobResponseBody[],
  dateRange: CalendarDateRange,
): ISetJobs => ({
  type: PLANNING_ACTION_TYPES.SET_JOBS,
  payload: { jobs, dateRange },
});

export const updateJob = ({
  jobData,
  revertEvent,
}: {
  jobData: UpdateCalendarJobType;
  revertEvent?: () => void;
}): IUpdateJob => ({
  type: PLANNING_ACTION_TYPES.UPDATE_JOB,
  payload: { data: jobData, revertEvent },
});

export const rescheduleJobs = (
  data: {
    _id: number;
    start: number;
    end: number;
  }[],
): IRescheduleJobs => ({
  type: PLANNING_ACTION_TYPES.RESCHEDULE_JOBS,
  payload: { data },
});

export const rescheduleJobsSuccess = (
  jobs: JobResponseBody[],
): IRescheduleJobsSuccess => ({
  type: PLANNING_ACTION_TYPES.RESCHEDULE_JOBS_SUCCESS,
  payload: { jobs },
});

export const updateJobLock = ({
  data,
}: {
  data: {
    id: number;
    locked?: boolean;
  };
}): IUpdateJobLock => ({
  type: PLANNING_ACTION_TYPES.UPDATE_JOB_LOCK,
  payload: { data },
});

export const updateJobSuccess = (job: JobResponseBody): IUpdateJobSuccess => ({
  type: PLANNING_ACTION_TYPES.UPDATE_JOB_SUCCESS,
  payload: { job },
});

export const addCalendarJob = (
  orderId: number,
  job: JobResponseBody,
): IAddCalendarJob => ({
  type: PLANNING_ACTION_TYPES.ADD_CALENDAR_JOB,
  payload: { orderId, job },
});

export const removeCalendarJob = (job: LocalJobType): IRemoveCalendarJob => ({
  type: PLANNING_ACTION_TYPES.REMOVE_CALENDAR_JOB,
  payload: { job },
});

export const filterOrders = (filters: FilterType): IFilterOrders => ({
  type: PLANNING_ACTION_TYPES.FILTER_ORDERS,
  payload: { filters },
});

export const filterOrdersSuccess = ({
  orders,
  filters,
}: {
  orders: OrderResponseBody[];
  filters: FilterType;
}): IFilterOrdersSuccess => ({
  type: PLANNING_ACTION_TYPES.FILTER_ORDERS_SUCCESS,
  payload: { orders, filters },
});

export const setFilters = (filters: FilterType): ISetFilters => ({
  type: PLANNING_ACTION_TYPES.SET_FILTERS,
  payload: { filters },
});

export const setShowStatusColor = (
  eventColor: CalendarEventColor,
): ISetStatusColor => ({
  type: PLANNING_ACTION_TYPES.SET_SHOW_STATUS_COLOR,
  payload: { eventColor },
});

export const setRowScale = (value: number): ISetRowScale => ({
  type: PLANNING_ACTION_TYPES.SET_ROW_SCALE,
  payload: { value },
});

export const updateMultipleJobs = ({
  jobsForChange,
}: {
  jobsForChange: UpdateCalendarJobType[];
}): IUpdateMultipleJobs => ({
  type: PLANNING_ACTION_TYPES.UPDATE_MULTIPLE_JOBS,
  payload: { jobsForChange },
});

export const updateMultipleJobsSuccess = (
  newJobs: JobResponseBody[],
): IUpdateMultipleJobsSuccess => ({
  type: PLANNING_ACTION_TYPES.UPDATE_MULTIPLE_JOBS_SUCCESS,
  payload: { newJobs },
});

export const setCalendarCurrentView = (
  view: string,
): ISetCalendarCurrentView => ({
  type: PLANNING_ACTION_TYPES.SET_CALENDAR_CURRENT_VIEW,
  payload: { view },
});

export const setCalendarCurrentViewRange = (
  dateRange: CalendarDateRange,
): ISetCalendarCurrentViewRange => ({
  type: PLANNING_ACTION_TYPES.SET_CALENDAR_CURRENT_VIEW_RANGE,
  payload: { dateRange },
});

export const assignMachine = (id: number, machine: number): IAssignMachine => ({
  type: PLANNING_ACTION_TYPES.ASSIGN_MACHINE,
  payload: { id, machine },
});

export const updateJobLoading = (loading: boolean): IUpdateJobLoading => ({
  type: PLANNING_ACTION_TYPES.UPDATE_JOB_LOADING,
  payload: { loading },
});

export const assignMachineStatus = (
  error: boolean,
  success: boolean,
): IAssignMachineStatus => ({
  type: PLANNING_ACTION_TYPES.ASSIGN_MACHINE_STATUS,
  payload: { error, success },
});

export const printEmployeeCalendar = (payload): IPrintEmployeeCalendar => ({
  type: PLANNING_ACTION_TYPES.PRINT_EMPLOYEE_CALENDAR,
  payload: {
    employee: payload.employee,
    start: payload.start,
    end: payload.end,
  },
});

export const setSelectedEmployees = (
  payload: number[],
): ISetSelectedEmployees => ({
  type: PLANNING_ACTION_TYPES.SET_SELECTED_EMPLOYEES,
  payload,
});
