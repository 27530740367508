import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { GridRowId } from "@mui/x-data-grid";
import { ImportParcelTableRow } from "Screens/parcels/types";
import { NotificationService } from "Services";
import { TFunction } from "i18next";
import { MutableRefObject, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMerge } from "./mergeParcels";
import { useSplit } from "./splitParcel";
import { PolygonWithSize } from "./types";

export const usePolygonOperations = (
  parcelData: ImportParcelTableRow[],
  editParcelId: GridRowId | undefined,
  parcelIdsToMerge: number[],
  splitLineRef: MutableRefObject<google.maps.Polyline | null>,
  onSplitComplete: (
    newParcelData: ImportParcelTableRow[],
    oldParcelId: number,
  ) => void,
  onMergeComplete: (
    newParcelData: ImportParcelTableRow[],
    mergedParcelId: number,
  ) => void,
) => {
  const [t] = useTranslation();

  const split = useSplit();
  const merge = useMerge();

  const splitParcel = useCallback(() => {
    try {
      if (editParcelId === undefined) {
        return;
      }

      const lineCoords = splitLineRef.current?.getPath().getArray();
      if (lineCoords?.length) {
        const parcel = parcelData.find(
          (parcel) => parcel._id === +editParcelId,
        );

        if (!parcel) {
          throw Error("Unable to split parcels", {
            cause: t(
              "notification.error.polygonSplitting.cause.operationFailed",
            ),
          });
        }

        const splitParcelPolygonsWithSize = split(parcel.shape, lineCoords);

        onSplitComplete(
          getNewParcelDataAfterSplit(
            parcel,
            splitParcelPolygonsWithSize,
            parcelData,
            t,
          ),
          parcel._id,
        );
      }
    } catch (err) {
      let message = "operationFailed";
      if (err instanceof Error && err.cause) {
        message = err.cause as string;
      }
      NotificationService.error(
        t("notification.error.polygonSplitting.title"),
        message,
      );
    }
  }, [editParcelId, onSplitComplete, parcelData, split, splitLineRef, t]);

  const mergeParcels = useCallback(() => {
    try {
      const parcelsDataToMerge = parcelData.filter((p) =>
        parcelIdsToMerge.includes(p._id),
      );

      if (parcelsDataToMerge.length == 2)
        throw new Error(undefined, {
          cause: t("notification.error.polygonSplitting.cause.noTwoSelected"),
        });

      const parcelPolygons = parcelsDataToMerge.map((p) => p.shape);

      const mergedPolygonWithSize = merge(parcelPolygons);

      const mergeParcelData = mergeParcelsData(parcelsDataToMerge);
      mergeParcelData._id = parcelIdsToMerge[0];
      mergeParcelData.shape = mergedPolygonWithSize.shape;
      mergeParcelData.size = mergedPolygonWithSize.size;

      const firstParcelOrigIds = parcelsDataToMerge[0].originalParcelIds;
      const secondParcelOrigIds = parcelsDataToMerge[1].originalParcelIds;

      mergeParcelData.originalParcelIds = [
        ...new Set([
          ...(firstParcelOrigIds ?? []),
          ...(secondParcelOrigIds ?? []),
        ]),
      ];

      const parcelsToRemove = parcelIdsToMerge.splice(1);
      const newParcelData = parcelData
        .filter((p) => !parcelsToRemove.includes(p._id))
        .map((p) => {
          if (p._id === parcelIdsToMerge[0]) {
            return mergeParcelData;
          }
          return p;
        });

      onMergeComplete(newParcelData, mergeParcelData._id);
    } catch (err) {
      let message = "operationFailed";
      if (err instanceof Error && err.cause) {
        message = err.cause as string;
      }
      NotificationService.error(
        t("notification.error.polygonMerging.title"),
        t("notification.error.polygonMerging.cause." + message),
      );
    }
  }, [merge, onMergeComplete, parcelData, parcelIdsToMerge, t]);

  return {
    splitParcel,
    mergeParcels,
  };
};

const getNewParcelDataAfterSplit = (
  originalParcel: ImportParcelTableRow,
  newPolygons: PolygonWithSize[],
  parcelData: ImportParcelTableRow[],
  t: TFunction<"translation", undefined>,
): ImportParcelTableRow[] => {
  if (newPolygons.length !== 2)
    throw new Error("Unable to split polygon", {
      cause: t(
        "notification.error.polygonSplitting.cause.notTwoResultingParcels",
      ),
    });

  const { parcelOne, parcelTwo } = getSplitParcels(
    parcelData,
    originalParcel,
    newPolygons,
  );

  const newParcelData = replaceOriginalParcel(
    parcelData,
    originalParcel,
    parcelOne,
    parcelTwo,
  );

  return newParcelData;
};

const replaceOriginalParcel = (
  parcelData: ImportParcelTableRow[],
  originalParcel: ImportParcelTableRow,
  parcelOne: ImportParcelTableRow,
  parcelTwo: ImportParcelTableRow,
) => {
  const originalParcelIndex = parcelData.findIndex(
    (parcel) => parcel._id === originalParcel._id,
  );
  const newParcelData = [...parcelData];
  newParcelData.splice(originalParcelIndex, 1, parcelOne, parcelTwo);
  return newParcelData;
};

const getSplitParcels = (
  parcelData: ImportParcelTableRow[],
  originalParcel: ImportParcelTableRow,
  newPolygons: PolygonWithSize[],
) => {
  const maxId = [...parcelData].sort((a, b) => a._id - b._id)[
    parcelData.length - 1
  ]._id;
  const parcelOne = {
    ...originalParcel,
    _id: maxId + 1,
    shape: newPolygons[0].shape,
    sizeField: SIZE_FIELD.SIZE_AUTOMATIC,
    name: `${originalParcel.name} (1)`,
    size: newPolygons[0].size,
    disabled: false,
    originalParcelIds: originalParcel.originalParcelIds,
  };
  const parcelTwo = {
    ...originalParcel,
    _id: maxId + 2,
    shape: newPolygons[1].shape,
    sizeField: SIZE_FIELD.SIZE_AUTOMATIC,
    name: `${originalParcel.name} (2)`,
    size: newPolygons[1].size,
    originalParcelIds: originalParcel.originalParcelIds,
  };
  return { parcelOne, parcelTwo };
};

const mergeParcelsData = (parcels: ImportParcelTableRow[]) =>
  parcels
    .reverse()
    .reduce((accParcel: ImportParcelTableRow, parcel: ImportParcelTableRow) => {
      const data = { ...accParcel, ...parcel };
      if (parcel.flurstuecksnummern) {
        data.flurstuecksnummern = [
          ...(accParcel.flurstuecksnummern || []),
          ...(parcel.flurstuecksnummern || []),
        ];
      }

      return data;
    }, {} as ImportParcelTableRow);
