import { TelemetryType } from "Enums";
import { type OptionType } from "Types";

const isDev = process.env.NODE_ENV === "development";
export const TELEMETRY_INFO_POLL_TIMEOUT = isDev ? 5000 : 2000;
export const TELEMETRY_TICK_POLL_TIMEOUT = isDev ? 5000 : 2000;
export const MACHINES_POLL_TIMEOUT = isDev ? 5000 : 2000;
export const DEFAULT_LANG = "de";
export const DEFAULT_COLOR_MODE = "light";
export const SUCCESS_NOTIFICATION_TIME_APPEARANCE = 3000;
export const GPS_REQUEST_TIMEOUT = 3000;
export const FILE_UPLOAD_TIMEOUT = 120000;
export const MAP_UPDATE_TIMEOUT = 2000;
export const CALENDAR_LINE_EMPLOYEES_NUMBER = 2;
export const MAX_TRACK_DATERANGE = 86400000;

/** Used in places such as displaying telematics values */
export const FLOAT_DECIMAL_PLACES = 0;

export const ERO_API_TIMEOUT = 15000;
export const ERO_API_TIMEOUT_DRIVER = 15000;
export const TELEMETRY_API_TIMEOUT = 5000;
export const DROPDOWN_DEBOUNCE = 300;

/** Telemetry entries which are sent as-is, without diff calculation */
export const TELEMETRY_ABSOLUTE_KEYS = [
  TelemetryType.lat,
  TelemetryType.lon,
  TelemetryType.machine_mode,
  TelemetryType.error,
  TelemetryType.device,
  TelemetryType.accuracy,
  TelemetryType.fuelUsedMlSinceStart,
];

/**
 * These values should be always sent,
 * even if no job is processed or table isn't connected to tel. box */
export const TELEMETRY_OFFLINE_KEYS = [
  TelemetryType.lon,
  TelemetryType.lat,
  TelemetryType.device,
  TelemetryType.accuracy,
];

export const languageOptions: OptionType[] = [
  { value: "de", label: "Deutsch" },
  { value: "us", label: "English" },
];

/**
 * Body for API requests to get all entities w/o pagination
 */
export const GET_ALL_PARAMS = { page: -1, pageLength: 1 };

export const LAZY_LOAD_PAGE_LENGTH = 20;
/**
 * How many pixels of scroll should remain after which
 * the table begins to load more items
 * equals to `scrollHeight - scrollTop - offsetHeight` */
export const LAZY_LOAD_SCROLL_TRESHOLD = 40;

/** Max possible accuracy for GPS tracking */
export const MAX_ACCURACY = 1;

export const IS_CHROME = !!(window as any).chrome;
export const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);

export const WEBSOCKET_RECV_TIMEOUT = 10000;
export const WEBSOCKET_RECONNECT_WAIT = 5000;
export const WEBSOCKET_BULK_THROTTLE_TIMEOUT = 2000;
export const WEBSOCKET_PING_PONG_TIMEOUT = 5000;
