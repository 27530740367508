import { CustomerOutside } from "@ero/app-common/models/Customer";
import { AddOutlined } from "@mui/icons-material";
import { Container, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import { AvatarUpload, ConfirmDialog, Input } from "Components";
import { useStaticDropdownValues } from "Hooks/dropdownValues";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { PersonModal } from "ProjectComponents/personModal";
import { deleteCustomerPerson } from "Store/customerPersons/actions";
import { LocalCustomerType } from "Store/customers/specs";
import { resizeTo } from "Utils";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PersonItem } from "./personItem";

interface IProps {
  persons: CustomerOutside["persons"];
  mainContactId?: number;
  editMode: boolean;
}

export const GeneralInformation: React.FC<IProps> = ({
  persons,
  mainContactId,
  editMode,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { values, setFieldValue } =
    useFormikContext<Partial<LocalCustomerType>>();

  const [isCustomerEmployeeModalOpen, setIsCustomerEmployeeModalOpen] =
    useState(false);
  const [personToUpdateId, setPersonToUpdateId] = useState<number>();
  const [personToDeleteId, setPersonToDeleteId] = useState<number>();

  const handleAvatarUpload = (value: File) => {
    const resizedImage = resizeTo(value, 150);
    resizedImage.then((image: string) => {
      setFieldValue("avatar", image);
    });
  };

  const { values: categories, loading: loadingCategories } =
    useStaticDropdownValues("category");

  return (
    <>
      <Container maxWidth={editMode ? "xl" : "lg"}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={editMode ? 8 : 12}
            container
            spacing={2}
            alignContent={"flex-start"}
          >
            <Grid item xs={12}>
              <AvatarUpload
                name="customer"
                letterAvatar={values.companyName?.[0] ?? "C"}
                image={values.avatar}
                loading={false}
                onSubmit={handleAvatarUpload}
              />
            </Grid>
            <Grid
              item
              xs={12}
              xl={6}
              container
              spacing={2}
              alignContent={"flex-start"}
            >
              <Grid item xs={3}>
                <Input
                  //in the UI it is shown as "Internal ID", but saved as 'label'
                  //NOT internalId (the company-scoped counter)
                  name="label"
                  type="text"
                  label={t("customers.fields.internalId")}
                />
              </Grid>
              <Grid item xs={9}>
                <StaticSelectWithAutoComplete
                  name="category"
                  options={categories}
                  label={t("customers.fields.category")}
                  loading={loadingCategories}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="companyName"
                  type="text"
                  mandatory={true}
                  label={t("customers.fields.name")}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="street"
                  type="text"
                  label={t("customers.fields.street")}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name="zipCode"
                  type="text"
                  label={t("customers.fields.zipCode")}
                />
              </Grid>
              <Grid item xs={9}>
                <Input
                  name="city"
                  type="text"
                  label={t("customers.fields.city")}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="phone"
                  type="text"
                  label={t("customers.fields.phone")}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="mobile"
                  type="text"
                  label={t("customers.fields.mobile")}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="email"
                  type="email"
                  label={t("general.fields.email")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} xl={6} container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label={t("general.fields.notes")}
                  multiline
                  name="notes"
                  rows={10}
                />
              </Grid>
            </Grid>
          </Grid>
          {editMode && (
            <Grid
              item
              xs={4}
              container
              spacing={2}
              alignContent={"flex-start"}
              justifyContent={"center"}
            >
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t("customers.fields.persons")}
                </Typography>
              </Grid>
              <Grid item xs={12} container spacing={1} justifyContent="center">
                {persons.length === 0 && (
                  <>
                    <Grid item>
                      <Typography variant="h6">
                        {t("customers.noPersons")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {t("customers.noPersonsHint")}
                      </Typography>
                    </Grid>
                  </>
                )}
                {persons.map((p) => (
                  <Grid item xs={12} key={p._id}>
                    <PersonItem
                      person={p}
                      mainContact={p._id == mainContactId}
                      setPersonIdToUpdate={setPersonToUpdateId}
                      setPersonIdToDelete={setPersonToDeleteId}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  onClick={() => setIsCustomerEmployeeModalOpen(true)}
                >
                  <AddOutlined />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
      <PersonModal
        isOpen={isCustomerEmployeeModalOpen || !!personToUpdateId}
        onClose={() => {
          setPersonToUpdateId(undefined);
          setIsCustomerEmployeeModalOpen(false);
        }}
        person={persons.find((p) => p._id == personToUpdateId)}
        mainContactId={mainContactId}
      />
      <ConfirmDialog
        isOpen={!!personToDeleteId}
        onApprove={() => {
          if (personToDeleteId) {
            dispatch(deleteCustomerPerson([personToDeleteId]));
            setPersonToDeleteId(undefined);
          }
        }}
        onClose={() => setPersonToDeleteId(undefined)}
        title={t("components.deleteDialog.title")}
        text={t("components.deleteDialog.textFirstPart", {
          count: 1,
        })}
        text_2={t("components.deleteDialog.textSecondPart")}
        positiveButtonText={t("components.deleteDialog.deleteButton")}
        negativeButtonText={t("components.deleteDialog.cancelButton")}
      />
    </>
  );
};
