import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { Grid } from "@mui/material";
import { DateInput } from "ProjectComponents/FormikDateInput/DateInput";
import { DateTimeInput } from "ProjectComponents/FormikDateInput/DateTimeInput";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValuesType } from "../../../formConfig";

export const DateInputContent: React.FC = () => {
  const [t] = useTranslation();
  const { values } = useFormikContext<ValuesType>();

  const dateMode = values.dateRestrictions.dateMode;
  const timeMode = values.dateRestrictions.timeMode;

  const content = useMemo(() => {
    switch (dateMode) {
      case DateMode.FIXEDDATE:
        if (timeMode == DateTimeMode.WITHTIME) {
          return (
            <Grid item xs={6}>
              <DateTimeInput
                name="dateRestrictions.date"
                label={t("orders.createModal.date")}
                minDate={dayjs()}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item xs={6}>
              <DateInput
                name="dateRestrictions.date"
                label={t("orders.createModal.date")}
                minDate={dayjs()}
              />
            </Grid>
          );
        }
      case DateMode.DATERANGE:
        if (timeMode == DateTimeMode.WITHTIME) {
          return (
            <>
              <Grid item xs={6}>
                <DateTimeInput
                  name="dateRestrictions.startDate"
                  label={t("orders.createModal.startDate")}
                  minDate={dayjs()}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput
                  name="dateRestrictions.endDate"
                  label={t("orders.createModal.endDate")}
                  minDate={dayjs(values.dateRestrictions.startDate) ?? dayjs()}
                />
              </Grid>
            </>
          );
        } else {
          return (
            <>
              <Grid item xs={6}>
                <DateInput
                  name="dateRestrictions.startDate"
                  label={t("orders.createModal.startDate")}
                  minDate={dayjs()}
                />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  name="dateRestrictions.endDate"
                  label={t("orders.createModal.endDate")}
                  minDate={dayjs(values.dateRestrictions.startDate) ?? dayjs()}
                />
              </Grid>
            </>
          );
        }
    }
  }, [dateMode, t, timeMode, values.dateRestrictions.startDate]);

  return <>{content}</>;
};
