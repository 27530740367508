import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Alert, Divider, Grid } from "@mui/material";
import { AppState } from "Store";
import { useFormikContext } from "formik";
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ValuesType } from "../../formConfig";
import { JobCheckbox } from "./components/jobCheckbox";
import { ServiceCheckbox } from "./components/serviceCheckbox";

interface IJobCreationScreen {
  selectedParcels: number[];
  selectedServices: number[];
  allParcelsOfCustomer: ParcelsResponseBody["data"];
}

const getJobIndex = (
  parcelIndex: number,
  serviceCount: number,
  serviceIndex: number,
) => parcelIndex * serviceCount + serviceIndex;

export const JobCreationScreen: React.FC<IJobCreationScreen> = ({
  selectedParcels,
  selectedServices,
  allParcelsOfCustomer,
}) => {
  const [t] = useTranslation();

  const { errors, validateField, setFieldValue, values } =
    useFormikContext<ValuesType>();

  const { list: allServicesList } = useSelector(
    (state: AppState) => state.services,
  );

  useEffect(() => {
    validateField("jobs");
  }, [validateField, selectedServices, selectedParcels]);

  useEffect(() => {
    if (selectedServices.length === 1) {
      setTimeout(() => {
        const jobs = selectedParcels.map((parcel) => ({
          parcel,
          service: selectedServices[0],
          create: true,
        }));
        setFieldValue("jobs", jobs);
      }, 1);
    }
  }, [selectedParcels, selectedServices, setFieldValue]);

  const allServicesCheckedArr = useMemo(
    () =>
      selectedServices.map((id) => {
        if (
          values.jobs.filter((job) => job?.service === id && job?.create)
            .length === selectedParcels.length
        )
          return true;
      }),
    [selectedServices, selectedParcels, values.jobs],
  );

  const handleServiceCheckboxChange = useCallback(
    (create: boolean, service: number, serviceIndex: number) => {
      const jobsOfServiceColumn = selectedParcels
        .map((parcel, parcelIndex) => ({
          [parcelIndex * selectedServices.length + serviceIndex]: {
            parcel,
            service,
          },
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});

      // ensure that length of jobs field is cov<yering the whole matrix
      values.jobs.length = selectedParcels.length * selectedServices.length;
      // spread into new array so that iterating does not skip "empty" values (empty, i.e. uninitialized, slots in JS arrays are not enumerable)
      const jobs = [...values.jobs];

      const updatedJobs = jobs.map((job, jobIndex) => {
        if (jobIndex in jobsOfServiceColumn) {
          return { ...jobsOfServiceColumn[jobIndex], create };
        }
        return job;
      });

      // update all jobs at once, so that we get propper validation
      // when updating one by one, during validation we always get the old values plus the one update value
      setFieldValue("jobs", updatedJobs);
    },
    [selectedParcels, selectedServices.length, setFieldValue, values.jobs],
  );

  const gridItems = useMemo(
    () => (
      <>
        <Grid item xs={1}></Grid>
        {selectedServices.map((service, serviceIndex) => (
          <Grid key={service} item xs={1}>
            <ServiceCheckbox
              label={
                allServicesList.find(
                  (fullService) => fullService._id === service,
                )?.name || ""
              }
              isChecked={allServicesCheckedArr[serviceIndex]}
              onChange={(create) =>
                handleServiceCheckboxChange(create, service, serviceIndex)
              }
            />
          </Grid>
        ))}
        {selectedParcels
          .map((parcel, parcelIndex) => (
            <Fragment key={`r${parcelIndex}`}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={1}>
                {
                  allParcelsOfCustomer.find(
                    (fullParcel) => fullParcel._id === parcel,
                  )?.name
                }
              </Grid>
              {selectedServices.map((service, serviceIndex) => (
                <Grid key={`r${parcel}c${service}`} item xs={1}>
                  <JobCheckbox
                    parcel={parcel}
                    service={service}
                    index={getJobIndex(
                      parcelIndex,
                      selectedServices.length,
                      serviceIndex,
                    )}
                  />
                </Grid>
              ))}
            </Fragment>
          ))
          .flat()}
      </>
    ),
    [
      allParcelsOfCustomer,
      allServicesCheckedArr,
      allServicesList,
      handleServiceCheckboxChange,
      selectedParcels,
      selectedServices,
    ],
  );

  const missingJobForOneOrMoreParcels = useMemo(() => {
    const remainingParcels = new Set(selectedParcels);
    values.jobs.forEach((job) => {
      if (job?.create) {
        remainingParcels.delete(job.parcel);
      }
    });
    return remainingParcels.size > 0;
  }, [selectedParcels, values.jobs]);

  return (
    <>
      {errors.jobs && (
        <Alert severity="info" sx={{ mt: 2 }}>
          {typeof errors.jobs == "string" && errors.jobs}
        </Alert>
      )}
      {!errors.jobs && missingJobForOneOrMoreParcels && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {t("orders.createModal.missingJobs", {
            count: selectedServices.length,
          })}
        </Alert>
      )}
      <Grid
        container
        spacing={2}
        pt={2}
        mt={2}
        columns={selectedServices.length + 1}
        justifyContent="center"
        alignItems="center"
      >
        {gridItems}
      </Grid>
    </>
  );
};
