import { type EventInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import { useTheme } from "@mui/material";
import { arraysEqual } from "Utils";
import classNames from "classnames";
import i18n from "i18n";
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Menu } from "./components";
import { getBaseCalendarConfig } from "./config";
import {
  useCalendarButtons,
  useDragStart,
  useDragStop,
  useEventChange,
  useEventClick,
  useInteraction,
  useRenderEvent,
  useSidebarToggle,
} from "./hooks";
import "./styles.css";
import { type CalendarProps } from "./types";

function noop() {}

export const Calendar: React.FC<PropsWithChildren<CalendarProps>> = (props) => {
  const {
    calendarRef,
    events,
    eventOverlap,
    hasError = false,
    initialView,
    isToolbarLeftPadding,
    locale = "en",
    onDateRangeChoose = noop,
    onDateSet = noop,
    renderResource,
    resources,
    resourceLaneDidMount,
    resourceLabelDidMount,
    resourceOrder,
    initialDate,
    rowScaleValue,
    showEventColorSwitchers,
    showMenu,
    onEventColorChange,
    showRowScale,
    onRowScaleChange,
    menuOptions,
    showHelpBtn,
    onHelpBtnClick,
    appointmentColorization,
    children,
  } = props;

  const [internalEvents, setInternalEvents] = useState<EventInput[]>(events);
  // prevent live updates while dragging
  const [isDragging, setIsDragging] = useState(false);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const { swipeProps, interactionProps } = useInteraction(props, calendarRef);
  const renderEventContentLocal = useRenderEvent(props);
  const handleEventChange = useEventChange(props, {
    internalEvents,
    setInternalEvents,
  });
  const onDragStopHandler = useDragStop(props, {
    internalEvents,
    setInternalEvents,
    setIsDragging,
  });
  const onDragStartHandler = useDragStart(props, { setIsDragging });
  const handleEventClick = useEventClick(props);
  const calendarLocale = useMemo(
    () => (locale === "us" ? "en" : locale),
    [locale],
  );
  const [showLeftButtons, _] = useState(true);
  const slotMinWidth = useMemo(
    () => 15 * ((rowScaleValue || 25) / 25),
    [rowScaleValue],
  );

  const { headerToolbar } = useCalendarButtons(showLeftButtons, props.showMenu);

  const theme = useTheme();
  const mode = theme.palette.mode;

  const buttonTexts = {
    list: i18n.t("components.calendar.appointmentList"),
  };

  useSidebarToggle(calendarRef);

  useEffect(() => {
    if (!isDragging && !arraysEqual(events, internalEvents)) {
      setInternalEvents(events);
    }
  }, [events, hasError, isDragging, internalEvents]);

  // useEffect(() => {
  //   let resizeObserver: ResizeObserver;

  //   if (calendarWrapperRef.current) {
  //     resizeObserver = new ResizeObserver(
  //       debounce((entries: ResizeObserverEntry[]) => {
  //         const targetWidth = entries[0].borderBoxSize[0].inlineSize;
  //         let newShowLeftButtons = true;
  //         if (targetWidth < 995) {
  //           newShowLeftButtons = false;
  //         }
  //         setShowLeftButtons(newShowLeftButtons);
  //       }, 100),
  //     );
  //     resizeObserver.observe(calendarWrapperRef.current);
  //   }

  //   return () => {
  //     resizeObserver.unobserve(calendarWrapperRef.current as Element);
  //   };
  // }, []);

  return (
    <div id="calendar-wrapper" className="calendar-wrapper">
      <div
        className={classNames(
          "calendar",
          isToolbarLeftPadding && "padding-toolbar",
        )}
        ref={calendarWrapperRef}
      >
        <div
          className={classNames(
            mode == "dark" ? "darkmode" : "",
            "calendar-main",
            `row${rowScaleValue}`,
          )}
          {...swipeProps}
        >
          <FullCalendar
            {...getBaseCalendarConfig(theme)}
            {...interactionProps}
            nowIndicator
            ref={calendarRef}
            headerToolbar={headerToolbar}
            initialView={initialView}
            locale={calendarLocale}
            events={internalEvents} // alternatively, use the `events` setting to fetch from a feed
            select={onDateRangeChoose}
            eventContent={renderEventContentLocal} // custom render function
            eventClick={handleEventClick}
            eventReceive={handleEventChange}
            initialDate={initialDate}
            resourceLabelContent={renderResource}
            resourceLaneDidMount={resourceLaneDidMount}
            resourceLabelDidMount={resourceLabelDidMount}
            eventDragStop={onDragStopHandler}
            eventDragStart={onDragStartHandler}
            resources={resources}
            resourceOrder={resourceOrder}
            eventChange={handleEventChange}
            datesSet={onDateSet}
            eventOverlap={eventOverlap}
            slotMinWidth={slotMinWidth}
            buttonText={buttonTexts}
          />
          <div className="calendar-buttons-wrap">
            {children}
            {showMenu && (
              <Menu
                menuOptions={menuOptions}
                onEventColorChange={onEventColorChange}
                onHelpBtnClick={onHelpBtnClick}
                onRowScaleChange={onRowScaleChange}
                rowScaleValue={rowScaleValue}
                showEventColorSwitchers={showEventColorSwitchers}
                showHelpBtn={showHelpBtn}
                showRowScale={showRowScale}
                appointmentColorization={appointmentColorization}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
