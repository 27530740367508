import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { APISpec } from "@ero/app-common/routes/enforcer";
import {
  convertAreaToCustom,
  convertAreaToDefault,
} from "@ero/app-common/util/convertArea";
import { DeleteForever, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs as MuiTabs,
  Tab,
} from "@mui/material";
import { ConfirmDialog, Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { type AppState } from "Store";
import {
  createParcel as defaultCreateParcel,
  resetMeta as defaultResetMeta,
  updateParcel as defaultUpdateParcel,
  deleteParcels,
} from "Store/parcels";
import { LocalParcelType } from "Store/parcels/specs";
import { LanguageType } from "Types";
import { Form, Formik, FormikContextType } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { History, ParcelData, StructuralInformation } from "./screens";
import { FormikValues, getValidationSchema } from "./validationConfig";

const idHelper = <T extends { _id: number }>(
  field: T | undefined,
): number | undefined => {
  if (field === undefined) {
    return undefined;
  }
  if (field._id === -1) {
    return undefined;
  }
  return field._id;
};

interface IProps {
  parcel?: Partial<LocalParcelType>;
  isOpen: boolean;
  onClose: () => void;
  resetMeta?: () => AnyAction;
  onSuccess?: () => void;
  createParcel?: (data: APISpec["/parcels/create"]["body"]) => AnyAction;
  updateParcel?: (data: APISpec["/parcels/update"]["body"]) => AnyAction;
  getStoreState?: (store: AppState) => any;
}

export const ParcelInformationModal: React.FC<IProps> = ({
  parcel,
  isOpen,
  onClose,
  resetMeta = defaultResetMeta,
  onSuccess,
  createParcel = defaultCreateParcel,
  updateParcel = defaultUpdateParcel,
  getStoreState = (store: AppState) => store.parcels,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { unitOfMeasurement } = useSelector(
    (store: AppState) => store.auth.companyData,
  );

  const formikRef = useRef<FormikContextType<FormikValues>>(null);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  const { success, loading } = useSelector(getStoreState);
  const selectedLang = useSelector(
    (state: AppState) => state.auth.selectedLang,
  ) as LanguageType;

  useEffect(() => {
    if (success) {
      onSuccess && onSuccess();
      dispatch(resetMeta());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, resetMeta, onClose, onSuccess]);

  useEffect(() => {
    if (resetMeta === undefined) {
      return;
    }
    return () => {
      dispatch(resetMeta());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetMeta]);

  const editMode = useMemo(() => !!parcel?._id, [parcel?._id]);

  const initialValues: FormikValues = useMemo(
    () => ({
      ...parcel,
      name: parcel?.name || "",
      customer: idHelper(parcel?.customer),
      soil: idHelper(parcel?.soil),
      crop: idHelper(parcel?.crop),
      trainingType: idHelper(parcel?.trainingType),
      rowAmount: parcel?.rowAmount || "",
      rowGap: parcel?.rowGap || "",
      plantAmount: parcel?.plantAmount || "",
      plantGap: parcel?.plantGap || "",
      stain: parcel?.stain || "",
      color: parcel?.color || undefined,
      sizeManual: parcel?.sizeManual
        ? convertAreaToCustom(parcel?.sizeManual, unitOfMeasurement)
        : "",
      sizeAutomatic: parcel?.sizeAutomatic
        ? convertAreaToCustom(parcel?.sizeAutomatic, unitOfMeasurement)
        : 0,
      sizeField:
        parcel?.sizeField !== undefined
          ? parcel.sizeField
          : SIZE_FIELD.SIZE_MANUAL,
      flurstuecke: parcel?.flurstuecke || [],
      slope: parcel?.slope || "",
      slopeDirection: parcel?.slopeDirection || "",
      sizeImportedKAFL: parcel?.sizeImportedKAFL
        ? convertAreaToCustom(parcel?.sizeImportedKAFL, unitOfMeasurement)
        : "",
      sizeImportedNUFL: parcel?.sizeImportedNUFL
        ? convertAreaToCustom(parcel?.sizeImportedNUFL, unitOfMeasurement)
        : "",
      gemarkungsnummer: parcel?.gemarkungsnummer || "",
      flnr:
        parcel?.flnr === undefined || parcel.flnr === null ? "" : parcel.flnr,
      shape: parcel?.shape,
      isClosedPolygon: (parcel?.shape?.length ?? 0) > 2,
    }),
    [parcel, unitOfMeasurement],
  );

  const handleFormSubmit = useCallback(
    (values): void => {
      const data = { ...values };

      const defaultEmptyValue = parcel?._id ? null : undefined;

      if (data.sizeManual !== undefined && data.sizeManual != "")
        data.sizeManual = convertAreaToDefault(
          data.sizeManual,
          unitOfMeasurement,
          selectedLang,
        );
      else data.sizeManual = defaultEmptyValue;
      if (data.sizeAutomatic !== undefined)
        data.sizeAutomatic = convertAreaToDefault(
          data.sizeAutomatic,
          unitOfMeasurement,
          selectedLang,
        );
      else data.sizeAutomatic = defaultEmptyValue;
      if (data.rowAmount == "") data.rowAmount = defaultEmptyValue;
      if (data.rowGap == "") data.rowGap = defaultEmptyValue;
      if (data.plantAmount == "") data.plantAmount = defaultEmptyValue;
      if (data.plantGap == "") data.plantGap = defaultEmptyValue;
      if (data.clearingDate == "") data.clearingDate = defaultEmptyValue;
      if (data.plantingDate == "") data.plantingDate = defaultEmptyValue;
      if (data.slope == "") data.slope = defaultEmptyValue;
      if (data.slopeDirection == "") data.slopeDirection = defaultEmptyValue;
      if (data.stain === "") data.stain = defaultEmptyValue;
      if (data.gemarkungsnummer == "")
        data.gemarkungsnummer = defaultEmptyValue;
      if (data.flnr == "") data.flnr = defaultEmptyValue;
      if (data.plantingDate === null) data.plantingDate = undefined;
      if (data.clearingDate === null) data.clearingDate = undefined;
      /* Don't include color in the update action. It's set and fetched from
       another endpoint */
      delete data.color;

      if (!!parcel && parcel._id) {
        dispatch(updateParcel(data));
      } else {
        dispatch(createParcel(data));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createParcel, parcel, selectedLang, unitOfMeasurement, updateParcel],
  );

  const deleteParcel = () => {
    if (parcel?._id) dispatch(deleteParcels([parcel?._id]));
    setShowConfirmDeleteModal(false);
    onClose();
  };

  const tabs = useMemo(() => {
    const tabs = [
      <div role="tabpanel" hidden={currentTab != 0} key="parceldata">
        <ParcelData />
      </div>,
      <div role="tabpanel" hidden={currentTab != 1} key="structuralinformation">
        <StructuralInformation />
      </div>,
    ];
    if (!!parcel && !!parcel._id && editMode) {
      tabs.push(
        <div role="tabpanel" hidden={currentTab != 2} key="history">
          <History parcel={parcel} />
        </div>,
      );
    }
    return tabs;
  }, [currentTab, editMode, parcel]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      keepMounted={false}
    >
      <DialogTitle>
        {parcel?._id ? parcel.name : t("parcels.create")}
      </DialogTitle>
      <CloseButton onClose={onClose} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs
          value={currentTab}
          onChange={(_, newTab) => setCurrentTab(newTab)}
          variant="fullWidth"
        >
          <Tab label={t("general.labels.parcelData")} />
          <Tab label={t("general.labels.structuralInformation")} />
          {editMode && <Tab label={t("general.labels.history")} />}
        </MuiTabs>
      </Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        innerRef={formikRef}
        validateOnMount
        onSubmit={handleFormSubmit}
        validationSchema={getValidationSchema()}
      >
        {({ dirty, isValid, submitForm }) => (
          <>
            <DialogContent dividers>
              <Form style={{ height: "80%" }}>
                {tabs}
                <ConfirmDialog
                  isOpen={showConfirmDeleteModal}
                  onApprove={deleteParcel}
                  onClose={() => setShowConfirmDeleteModal(false)}
                  title={t("components.deleteDialog.title")}
                  text={t("components.deleteDialog.textFirstPart", {
                    count: 1,
                  })}
                  text_2={t("components.deleteDialog.textSecondPart")}
                  positiveButtonText={t("components.deleteDialog.deleteButton")}
                  negativeButtonText={t("components.deleteDialog.cancelButton")}
                />
              </Form>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  setShowConfirmDeleteModal(true);
                }}
                startIcon={<DeleteForever />}
              >
                {t("general.buttons.delete")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={submitForm}
                disabled={(!dirty && !!parcel?._id) || !isValid || loading}
                startIcon={!loading && <Save />}
              >
                {loading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
