import {
  Badge,
  Dashboard,
  Dataset,
  Engineering,
  Event,
  ExpandLess,
  ExpandMore,
  FmdGood,
  Group,
  PendingActions,
  PlaylistAdd,
} from "@mui/icons-material";
import { Collapse, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { MachineSvg } from "Assets/icons";
import { ROUTES } from "Constants";
import { type AppState } from "Store";
import { toggleSidebarSubmenu } from "Store/auth/actions";
import { default as React, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const iconSize = 20;

const ListItemButtonLink = React.forwardRef<any, any>((props, ref) => (
  <Link ref={ref} to={props.href} state={{}} {...props} />
));

ListItemButtonLink.displayName = "ListItemButtonLink";

const MenuItem: React.FC<{
  path: string;
  text: string;
  tooltip: string;
  icon?: JSX.Element;
  padding?: number;
  routerState?: any;
}> = ({ path: routerPath, text, tooltip, icon, padding, routerState }) => {
  const { pathname: currentPath } = useLocation();

  return (
    <ListItemButton
      sx={{
        ...(padding ? { pl: padding, transition: "padding-left 0.5s" } : {}),
      }}
      selected={currentPath === routerPath}
      component={Link}
      to={routerPath}
      state={routerState}
    >
      {icon && (
        <Tooltip title={tooltip}>
          <ListItemIcon sx={{ paddingLeft: "6px" }}>{icon}</ListItemIcon>
        </Tooltip>
      )}
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export default function MainMenu() {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const location = useLocation();

  const { sidebarOpen, sidebarSubMenuOpen } = useSelector(
    (state: AppState) => state.auth,
  );
  const { offset, limit, search } = useSelector(
    (state: AppState) => state.orders,
  );

  const orderRouterState = useMemo(
    () =>
      location.pathname.match(`^${ROUTES.MAIN.ORDERS}/\\d+`)
        ? { currentPage: offset / limit, numberOfRows: limit, search }
        : undefined,
    [location.pathname, offset, limit, search],
  );

  const handleSubMenuClick = useCallback(() => {
    dispatch(toggleSidebarSubmenu(!sidebarSubMenuOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarSubMenuOpen]);

  useEffect(() => {
    const currentLocation = location.pathname;
    const subMenuActive =
      currentLocation === ROUTES.MAIN.SERVICES ||
      currentLocation === ROUTES.MAIN.CUSTOMERS ||
      currentLocation === ROUTES.MAIN.MACHINES ||
      currentLocation === ROUTES.MAIN.EMPLOYEES ||
      currentLocation === ROUTES.MAIN.ORGANIZATION ||
      currentLocation === ROUTES.MAIN.PARCELS;
    if (subMenuActive) {
      dispatch(toggleSidebarSubmenu(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <List sx={{ overflow: "hidden" }}>
      <MenuItem
        path={ROUTES.MAIN.DASHBOARD}
        text={t("general.navigation.dashboard") as string}
        tooltip={t("general.navigation.dashboard") as string}
        icon={<Dashboard style={{ fontSize: iconSize }} />}
      />
      <MenuItem
        path={ROUTES.MAIN.PLANNING}
        text={t("general.navigation.calendar") as string}
        tooltip={t("general.navigation.calendar") as string}
        icon={<Event style={{ fontSize: iconSize }} />}
      />
      <MenuItem
        path={ROUTES.MAIN.ORDERS}
        text={t("general.navigation.orders") as string}
        tooltip={t("general.navigation.orders") as string}
        icon={<PendingActions style={{ fontSize: iconSize }} />}
        routerState={orderRouterState}
      />

      <ListItemButton onClick={handleSubMenuClick}>
        <Tooltip title={t("general.navigation.baseData") as string}>
          <ListItemIcon sx={{ paddingLeft: "6px" }}>
            <Dataset style={{ fontSize: iconSize }} />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={t("general.navigation.baseData")} />
        {sidebarOpen && (sidebarSubMenuOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={sidebarSubMenuOpen} timeout="auto" unmountOnExit>
        <List>
          <MenuItem
            path={ROUTES.MAIN.SERVICES}
            text={t("general.navigation.positions") as string}
            tooltip={t("general.navigation.positions") as string}
            icon={<Engineering style={{ fontSize: iconSize }} />}
            padding={sidebarOpen ? 4 : 2}
          />
          <MenuItem
            path={ROUTES.MAIN.CUSTOMERS}
            text={t("general.navigation.customers") as string}
            tooltip={t("general.navigation.customers") as string}
            icon={<Group style={{ fontSize: iconSize }} />}
            padding={sidebarOpen ? 4 : 2}
          />
          <MenuItem
            path={ROUTES.MAIN.MACHINES}
            text={t("general.navigation.machines") as string}
            tooltip={t("general.navigation.machines") as string}
            icon={<MachineSvg style={{ fontSize: 16 }} />}
            padding={sidebarOpen ? 4 : 2}
          />
          <MenuItem
            path={ROUTES.MAIN.EMPLOYEES}
            text={t("general.navigation.employees") as string}
            tooltip={t("general.navigation.employees") as string}
            icon={<Badge style={{ fontSize: iconSize }} />}
            padding={sidebarOpen ? 4 : 2}
          />
          <MenuItem
            path={ROUTES.MAIN.PARCELS}
            text={t("general.navigation.parcels") as string}
            tooltip={t("general.navigation.parcels") as string}
            icon={<FmdGood style={{ fontSize: iconSize }} />}
            padding={sidebarOpen ? 4 : 2}
          />
          <MenuItem
            path={ROUTES.MAIN.ORGANIZATION}
            text={t("general.navigation.organization") as string}
            tooltip={t("general.navigation.organization") as string}
            icon={<PlaylistAdd style={{ fontSize: iconSize }} />}
            padding={sidebarOpen ? 4 : 2}
          />
        </List>
      </Collapse>
    </List>
  );
}
